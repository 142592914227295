// React imports
import axios from "axios";

const API_URL = "https://infojersey.usjersey.com/Apis/BullseyeAPI"

// Makes the call to the bullseye API through a proxy to get the trait minimum and maximum values
export const getBullTraitMinMax = async () => {
    const apiOptions = {
        method: 'GET',
        url: `${API_URL}/traitminmax`,
        headers: {
            'X-API-Key': process.env.REACT_APP_BULLSEYE_API_KEY
        }
    }

    const request = await axios.request(apiOptions);
    return request;
}

// Makes the call to the bullseye API through a proxy to get the ai organizations
export const getAIOrganizations = async () => {
    const apiOptions = {
        method: 'GET',
        url: `${API_URL}/aiorganizations`,
        headers: {
            'X-API-Key': process.env.REACT_APP_BULLSEYE_API_KEY
        }
    }

    const request = await axios.request(apiOptions);
    return request;
}

// Makes the call to the bullseye API through a proxy to get the bull list
export const getBullList = async () => {
    const apiOptions = {
        method: 'GET',
        url: `${API_URL}/bulllist`,
        headers: {
            'X-API-Key': process.env.REACT_APP_BULLSEYE_API_KEY
        }
    }

    const request = await axios.request(apiOptions)
    return request;
}

// Makes the call to the bullseye API through a proxy to get the 3 generation pedigree for the provided bull
export const get3GenPedigree = async (registrationID) => {
    const options = {
        method: 'GET',
        url: `${API_URL}/report/3GOPP/{id}`,
        headers: {
            'X-API-Key': process.env.REACT_APP_BULLSEYE_API_KEY
        },
        params: {
            id: registrationID
        }
    }
    const request = await axios.request(options);
    return request;
}

// Makes a call to the bullseye API through a proxy to create a jersey mate mating group
export const createJerseyMateMating = async (herdNumber, groupName, registrationIDs) => {
    const apiOptions = {
        method: 'POST',
        url: `${API_URL}/matinggroup`,
        headers: {
            'X-API-Key': process.env.REACT_APP_BULLSEYE_API_KEY
        },
        params: {
            herdID: herdNumber,
            groupName: groupName,
            regIDs: JSON.stringify(registrationIDs),
        },
    }

    const request = await axios.request(apiOptions);
    return request;
}