// React Imports
import React, { useContext } from 'react'

// Packaged Component Imports
import * as FaIcons from 'react-icons/fa'
import { Container, Row, Col } from 'react-bootstrap'

// Context Imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// CSS Imports
import '../../stylesheets/AppliedFilters.css'

function AppliedFilters() {
    const {
        appliedFilters,
        resetAppliedFilter
    } = useContext(BullGridContext);

    const {
        theme
    } = useContext(ThemeContext);

    return (
        <Container fluid>
            <Row>
                <Col>
                    {appliedFilters.length > 0 && <h6 className={`AppliedFilters-Filter-Header ${theme}`}>Applied Filters:</h6>}
                    {
                        appliedFilters.map((item, index) => {
                            return (
                                <div key={index} className={`AppliedFilters-Filter-Item`}>
                                    <div className={`AppliedFilters-Filter-Label`}>
                                        {`${item.name}${item.value}`}
                                    </div>
                                    <FaIcons.FaTimes onClick={(e) => resetAppliedFilter(e, item.id)} className={`AppliedFilters-Filter-Icon`} />
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default AppliedFilters