// React imports
import React, { createContext } from 'react'

// Custom hook imports
import UseJerseyMateForm from '../hooks/UseJerseyMateForm'

// Creates the context to be used with the JerseyMate import form
export const JerseyMateFormContext = createContext();

function JerseyMateFormProvider({ children }) {

    // Sets the attributes for use in the context
    const {
        showEntryForm,
        isSubmitted,
        isProcessing,
        isSuccessfull,
        errors,
        apiResponseMessage,
        showSuccessForm,
        showErrorForm,
        showLoading,
        matingGroup,
        handleChange,
        handleSubmit,
        openEntryForm, 
        closeEntryForm, 
        closeSuccessForm,
        closeFailureForm,
    } = UseJerseyMateForm();

    return (
        <JerseyMateFormContext.Provider
            value={{
                showEntryForm,
                isSubmitted,
                isProcessing,
                isSuccessfull,
                errors,
                apiResponseMessage,
                showSuccessForm,
                showErrorForm,
                showLoading,
                matingGroup,
                handleChange,
                handleSubmit,
                openEntryForm, 
                closeEntryForm, 
                closeSuccessForm,
                closeFailureForm,
            }}>
            {children}
        </JerseyMateFormContext.Provider>
    )
}

export { JerseyMateFormProvider };