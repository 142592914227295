
// Model that represents the bull columns that can be selected for visibility
export const DefaultBullColumns = {
    chkDisplay_RegistrationID: true,
    chkDisplay_FullName: true,
    chkDisplay_NaabCode: true,
    chkDisplay_ShortName: false,
    chkDisplay_SireRegID: false,
    chkDisplay_SireShortName: false,
    chkDisplay_MgsRegID: false,
    chkDisplay_MgsShortName: false,
    chkDisplay_MggsRegID: false,
    chkDisplay_Polled: true,
    chkDisplay_BetaCaseinA2: true,
    chkDisplay_Efi: true,
    chkDisplay_Bbr: true,
    chkDisplay_BreedGroup: true,
    chkDisplay_AiStatus: true,
    chkDisplay_AiOrganization: false,
    chkDisplay_Jpi: true,
    chkDisplay_PtaMilk: true,
    chkDisplay_PtaFatPercent: true,
    chkDisplay_PtaFat: true,
    chkDisplay_PtaProteinPercent: true,
    chkDisplay_PtaProtein: true,
    chkDisplay_NetMerit: true,
    chkDisplay_CheeseMerit: true,
    chkDisplay_FluidMerit: true,
    chkDisplay_GrazingMerit: true,
    chkDisplay_RelProtein: true,
    chkDisplay_Jui: false,
    chkDisplay_PtaForeUdder: false,
    chkDisplay_PtaRearUdderHeight: false,
    chkDisplay_PtaRearUdderWidth: false,
    chkDisplay_PtaUdderCleft: false,
    chkDisplay_PtaUdderDepth: false,
    chkDisplay_PtaTeatPlacement: false,
    chkDisplay_PtaTeatLength: false,
    chkDisplay_PtaRtr: false,
    chkDisplay_PtaRts: false,
    chkDisplay_PtaFinalScore: false,
    chkDisplay_PtaStature: false,
    chkDisplay_PtaStrength: false,
    chkDisplay_PtaDairyForm: false,
    chkDisplay_PtaRumpAngle: false,
    chkDisplay_PtaThurlWidth: false,
    chkDisplay_PtaRearLegs: false,
    chkDisplay_PtaFootAngle: false,
    chkDisplay_PtaLiv: false,
    chkDisplay_PtaDpr: false,
    chkDisplay_PtaHcr: false,
    chkDisplay_PtaCcr: false,
    chkDisplay_PtaScs: false,
    chkDisplay_PtaProdLife: false,
    chkDisplay_Jh1: true,
    chkDisplay_Jns: true,
    chkDisplay_Hti: false,
    chkDisplay_PtaMfv: false,
    chkDisplay_PtaDab: false,
    chkDisplay_PtaKet: false,
    chkDisplay_PtaMas: false,
    chkDisplay_PtaMet: false,
    chkDisplay_PtaRpl: false,
    chkDisplay_Cfp: true,
}

// Model that represents the bull column layout for the visbility options
export const DefaultBullColumnControls = [
    {
        sectionHeader: "Recorded Information",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_FullName",
            label: "Full Name"
          },
          {
            type: "checkbox",
            name: "chkDisplay_NaabCode",
            label: "NAAB Code",
          },
          {
            type: "checkbox",
            name: "chkDisplay_ShortName",
            label: "Short Name",
          },
          {
            type: "checkbox",
            name: "chkDisplay_SireRegID",
            label: "Sire ID"
          },
          {
            type: "checkbox",
            name: "chkDisplay_SireShortName",
            label: "Sire Short Name",
          },
          {
            type: "checkbox",
            name: "chkDisplay_MgsRegID",
            label: "MGS ID"
          },
          {
            type: "checkbox",
            name: "chkDisplay_MgsShortName",
            label: "MGS Short Name",
          },
          {
            type: "checkbox",
            name: "chkDisplay_MggsRegID",
            label: "MGGS ID"
          },
          {
            type: "checkbox",
            name: "chkDisplay_Polled",
            label: "Polled",
          },
          {
            type: "checkbox",
            name: "chkDisplay_BetaCaseinA2",
            label: "Beta Casein A2"
          },
          {
            type: "checkbox",
            name: "chkDisplay_Efi",
            label: "GFI - Inbreeding",
          },
          {
            type: "checkbox",
            name: "chkDisplay_Bbr",
            label: "BBR - Breed Base Representation",
          },
          {
            type: "checkbox",
            name: "chkDisplay_BreedGroup",
            label: "Breed Group"
          },
          {
            type: "checkbox",
            name: "chkDisplay_AiStatus",
            label: "A.I. Status",
          },
          {
            type: "checkbox",
            name: "chkDisplay_AiOrganization",
            label: "A.I. Organization",
          }
        ]
      },
      {
        sectionHeader: "Production",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_Jpi",
            label: "JPI - Jersey Performance Index",
          },  
          {
            type: "checkbox",
            name: "chkDisplay_Cfp",
            label: "CFP - Combined Fat & Protein",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaMilk",
            label: "PTA Milk"
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaFatPercent",
            label: "% Fat"
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaFat",
            label: "PTA Fat",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaProteinPercent",
            label: "% Pro",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaProtein",
            label: "PTA Protein",
          },
          {
            type: "checkbox",
            name: "chkDisplay_NetMerit",
            label: "NM$ - Net Merit Dollars",
          },
          {
            type: "checkbox",
            name: "chkDisplay_CheeseMerit",
            label: "CM$ - Cheese Merit Dollars",
          },
          {
            type: "checkbox",
            name: "chkDisplay_FluidMerit",
            label: "FM$ - Fluid Merit Dollars",
          },
          {
            type: "checkbox",
            name: "chkDisplay_GrazingMerit",
            label: "GM$ - Grazing Merit Dollars",
          },
          {
            type: "checkbox",
            name: "chkDisplay_RelProtein",
            label: "REL % - Relative Percent"
          }
        ]
      },
      {
        sectionHeader: "Udder",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_Jui",
            label: "JUI - Jersey Udder Index"
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaForeUdder",
            label: "PTA FU - Fore Udder"
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRearUdderHeight",
            label: "PTA RUH - Rear Udder Height",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRearUdderWidth",
            label: "PTA RUW - Rear Udder Width",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaUdderCleft",
            label: "PTA UC - Udder Cleft",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaUdderDepth",
            label: "PTA UD - Udder Depth",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaTeatPlacement",
            label: "PTA TP - Teat Placement",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaTeatLength",
            label: "PTA TL - Teat Length",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRtr",
            label: "PTA RTR - Rear Teat Rear View",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRts",
            label: "PTA RTS - Rear Teat Side View",
          }
        ]
      },
      {
        sectionHeader: "Body",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_PtaFinalScore",
            label: "PTA FS - Final Score",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaStature",
            label: "PTA ST - Stature",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaStrength",
            label: "PTA SR - Strength",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaDairyForm",
            label: "PTA DF - Dairy Form",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRumpAngle",
            label: "PTA RA - Rump Angle",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaThurlWidth",
            label: "PTA RW - Rump Width",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRearLegs",
            label: "PTA RL - Rear Legs",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaFootAngle",
            label: "PTA FA - Foot Angle",
          }
        ]
      },
      {
        sectionHeader: "Fitness & Fertility",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_PtaLiv",
            label: "PTA LIV - Livability",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaDpr",
            label: "PTA DPR - Daughter Pregnancy Rate",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaHcr",
            label: "PTA HCR - Heifer Conception Rate",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaCcr",
            label: "PTA CCR - Cow Coneception Rate",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaScs",
            label: "PTA SCS - Somatic Cell Score",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaProdLife",
            label: "PTA PL - Productive Life"
          },
          {
            type: "checkbox",
            name: "chkDisplay_Jh1",
            label: "JH1 - Haplotype",
          },
          {
            type: "checkbox",
            name: "chkDisplay_Jns",
            label: "JNS - Haplotype",
          }
        ]
    },
    {
        sectionHeader: "CDCB Health Traits",
        options: [
          {
            type: "checkbox",
            name: "chkDisplay_Hti",
            label: "HTI - Health Trait Index",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaMfv",
            label: "PTA MFV - Milk Fever",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaDab",
            label: "PTA DAB - Displaced Abomasum",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaKet",
            label: "PTA KET - Ketosis",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaMas",
            label: "PTA MAS - Mastitis",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaMet",
            label: "PTA MET - Metritis",
          },
          {
            type: "checkbox",
            name: "chkDisplay_PtaRpl",
            label: "PTA RPL - Retained Placenta",
          },
        ]
      }
]
