// React imports 
import React, { useContext } from 'react'

// Custom component imports
import FilterPanel from './FilterPanel'

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import { DefaultFilterControls } from '../../models/BullFilter'

// Package component imports
import { Container, Accordion, Button } from 'react-bootstrap'
import { Tooltip } from '@mui/material'
import * as MdIcons from 'react-icons/md'

// Css imports
import '../../stylesheets/BullFilter.css'

function BullFilter() {
    const { resetBullFilter, activeFilterPanel } = useContext(BullGridContext);
    const { theme } = useContext(ThemeContext);

    return (
        <>
            <Container fluid className="BullFilter-Container">
                <Tooltip title="Reset" arrow>
                    <Button className={`BullFilter-Reset-Button ${theme}`} variant="secondary" onClick={resetBullFilter}>
                        <MdIcons.MdRestartAlt />
                    </Button>
                </Tooltip>
                <Accordion className={`BullFilter-Accordion ${theme}`} defaultActiveKey={activeFilterPanel}>
                    {
                        DefaultFilterControls.map((panel, key) => {
                            return (
                                <FilterPanel filterPanel={panel} key={key} index={key}></FilterPanel>
                            )
                        })
                    }
                </Accordion>
            </Container>
        </>
    )
}

export default BullFilter
