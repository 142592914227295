// React imports 
import React, { useContext } from "react";

// Context imports
import { ThemeContext, ThemeProvider } from '../../contexts/ThemeContext'

function Layout({ startingTheme, children }) {
    return (
        <ThemeProvider startingTheme={startingTheme}>
            <LayoutNoThemeProvider startingTheme={startingTheme}>
                {children}
            </LayoutNoThemeProvider>
        </ThemeProvider>
    )
}

function LayoutNoThemeProvider({ children }) {
    const { theme } = useContext(ThemeContext);
    return (
        <>
            <div className={theme === 'dark' ? 'container-fluid dark p-0' : 'container-fluid light p-0'}>
                {children}
            </div>
        </>
    )
}

export default Layout;