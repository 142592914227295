// React imports
import React, { useContext } from 'react'

// Context imports
import { JerseyMateFormContext } from '../../contexts/JerseyMateFormContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import * as RiIcons from 'react-icons/ri'

// CSS imports
import '../../stylesheets/JerseyMateFormFailed.css'

function JerseyMateFormFailed() {
    const { showErrorForm, closeFailureForm, apiResponseMessage } = useContext(JerseyMateFormContext);
    const { theme } = useContext(ThemeContext);

    return (
        <Dialog className={`JerseyMateFormFailed ${theme}`} open={showErrorForm} onClose={closeFailureForm}>
            <DialogContent className={`JerseyMateFormFailed-DialogContent ${theme}`}>
                <DialogContentText className='JerseyMateFormFailed-DialogContentText'>
                    <RiIcons.RiErrorWarningLine className='JerseyMateFormFailed-AlertIcon' />
                    Failure - Unable to import bulls into mating group, an error occurred!
                </DialogContentText>
                <DialogContentText className='JerseyMateFormFailed-DialogContentText'>
                    {apiResponseMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={`JerseyMateFormFailed-DialogActions ${theme}`}>
                <Button onClick={closeFailureForm}>Dismiss</Button>
            </DialogActions>
        </Dialog>
    )
}

export default JerseyMateFormFailed