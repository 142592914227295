// React imports
import React, { useContext } from 'react';

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Container, Row, Col } from 'react-bootstrap'
import { Skeleton } from '@mui/material';

// Asset imports
import UsJerseyLogo from '../../assets/images/USJerseyLogo.png'

// Css imports
import '../../stylesheets/BullGridHeader.css'

function BullGridHeader() {
    const { isBullDataLoading, selectedBullCount, totalBullCount } = useContext(BullGridContext);
    const { theme } = useContext(ThemeContext);

    return (
        isBullDataLoading ?
            <Skeleton variant="rectangular" className='BullGrid-Header-Container-Skeleton' />
            :
            <Container fluid className='BullGrid-Header-Container'>
                <Row className={`BullGrid-Header-Content ${theme}`}>
                    <Col xs='12' sm='12' md='8' lg='8' xl='8'>
                        <h3 className={`BullGrid-Header-Text ${theme}`}>Bull List: {selectedBullCount} of {totalBullCount} Bulls Selected</h3>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className={`BullGrid-Header-PoweredBy ${theme}`}>
                        Powered By
                        <img alt="USJerseyLogo" className='BullGrid-Header-UsJerseyLogo' src={UsJerseyLogo} />
                    </Col>
                </Row>
            </Container>
    )
}

export default BullGridHeader;
