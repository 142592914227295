// React imports
import { useEffect, useState } from 'react'

// Packaged component imports
import * as BullseyeAPI from '../services/BullseyeAPIService'

// Custom component imports
import validateJerseyMateForm from '../validations/ValidateJerseyMateForm';

const UseJerseyMateForm = () => {
    // Holds the state value to show the main JerseyMate entry form or not
    const [showEntryForm, setShowEntryForm] = useState(false);

    // Holds the state value to show if the form has been submitted or not
    const [isSubmitted, setIsSubmitted] = useState(false);

    // Holds the state value that determines if the form is being processed or not
    const [isProcessing, setIsProcessing] = useState(false);

    // Holds the state value of true if the form has been processed by the API successfully otherwise false
    const [isSuccessfull, setIsSuccessfull] = useState(false);

    // Holds the state value of the errors that are present in the form based on the validations
    const [errors, setErrors] = useState({});

    // Holds the state value of the errors that were returned by the API (validation errors)
    const [apiResponseMessage, setApiResponseMessage] = useState('');

    // True if the JerseyMate successfully processed form needs to show otherwise false
    const showSuccessForm = isSubmitted && !isProcessing && isSuccessfull;

    // True if the the JerseyMate error processed form needs to show otherwise false
    const showErrorForm = isSubmitted && !isProcessing && !isSuccessfull;

    // True if the loader gif needs to show otherwise false
    const showLoading = isSubmitted && isProcessing;

    // Holds the state value of the model that represents all fields in the form
    const [matingGroup, setMatingGroup] = useState({
        herdID: '',
        matingGroupName: '',
        regIDs: null,
    })

    // Use Effect to process the JerseyMate entry form
    useEffect(() => {
        // Check if the form is valid based on the errors count and if it has been set to processing
        if (Object.keys(errors).length === 0 && isProcessing) {

            // Once errors value has changed the form has been considered submitted
            setIsSubmitted(true);

            // Close the entry form
            setShowEntryForm(false);

            // Process the request to the bullseye API
            // Set the correct output to display based on the API response status
            BullseyeAPI.createJerseyMateMating(matingGroup.herdID, matingGroup.matingGroupName, matingGroup.regIDs)
                .then((res) => {
                    if (res.status === 200) {
                        setIsSuccessfull(true);
                    }
                    else {
                        setIsSuccessfull(false);
                        setApiResponseMessage(res.data)
                    }
                    setIsProcessing(false);
                }).catch((error) => {
                    setIsSuccessfull(false);
                    setIsProcessing(false);
                })
        }
    }, [errors]);

    // Handles the update of the values within the model for the form
    const handleChange = e => {
        const { name, value } = e.target;
        setMatingGroup({
            ...matingGroup,
            [name]: value,
        });
    }

    // Handles the submission and processing of the JerseyMate entry form
    const handleSubmit = e => {
        e.preventDefault();

        // Validate the form and set the validation errors
        // When errors is changed the use effect fires to handle the processing
        setErrors(validateJerseyMateForm(matingGroup));

        // Set that the form is currently processing
        setIsProcessing(true);
    }

    // Opens the JerseyMate entry form with the prepopulated registration IDs filled in the form that were selected 
    function openEntryForm(regIDs) {
        setMatingGroup({
            ...matingGroup,
            regIDs: regIDs
        });
        setShowEntryForm(true);
    }

    // Closes the active JerseyMate entry form
    function closeEntryForm() {
        setShowEntryForm(false);
    }

    // Closes the active JerseyMate success form
    function closeSuccessForm() {
        resetJerseyMateForm();
    }

    // Closes the active JerseyMate failed form
    function closeFailureForm() {
        resetJerseyMateForm();
    }

    // Resets all the JerseyMate form and processing variables back to defaults
    function resetJerseyMateForm() {
        // Reset the form values
        setMatingGroup({
            ...matingGroup,
            herdID: '',
            matingGroupName: '',
            regIDs: null,
        })

        // Clear out the errors
        setErrors({});

        // Reset submission and processing values
        setIsSubmitted(false);
        setIsProcessing(false);

        // Reset the response value
        setIsSuccessfull(false);
    }

    return {
        showEntryForm,
        isSubmitted,
        isProcessing,
        isSuccessfull,
        errors,
        apiResponseMessage,
        showSuccessForm,
        showErrorForm,
        showLoading,
        matingGroup,
        handleChange,
        handleSubmit,
        openEntryForm,
        closeEntryForm,
        closeSuccessForm,
        closeFailureForm,
    };
};

export default UseJerseyMateForm;