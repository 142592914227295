// React imports
import React from 'react'

// Custom component imports
import ThemeToggle from '../theme-toggler/ThemeToggle';

// Packaged component imports
import { Nav, Navbar, Container } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'

// Asset imports
import BullseyeLogo from '../../assets/images/BullseyeLogo.png'

// Css imports
import '../../stylesheets/Navigation.css'

function Navigation() {
    return (
        <>
            <Navbar className="Navigation-Navbar" expand="xl">
                <Container fluid className='Navigation-Container'>
                    <h6 className="Navigation-Welcome">Welcome To Bullseye</h6>
                    <Navbar.Brand className="Navigation-Brand" href="#">
                        <img src={BullseyeLogo} alt="Bullseye Logo" />
                        <h5 className='Navbar-Collapse-Brand-Name'>Bullseye</h5>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="Navigation-Toggle" />
                    <Navbar.Collapse id="Navigation-Toggle">
                        <Nav className="Navigation-Banner">
                            <Nav.Link className="Navigation-Banner-Item" href="tel:6148613636">
                                <FaIcons.FaPhoneAlt className="Navigation-Banner-Item-Icon" />
                                (614) 861-3636
                            </Nav.Link>
                            <Nav.Link className="Navigation-Banner-Item" href='https://www.google.com/maps/place/6486+E+Main+St,+Reynoldsburg,+OH+43068/@39.9569429,-82.8260669,17z/data=!3m1!4b1!4m5!3m4!1s0x8838637cfea2dff7:0x3f9170cc7d3c6605!8m2!3d39.9569429!4d-82.8238782' target='_blank' rel="noopener">
                                <FaIcons.FaLocationArrow className="Navigation-Banner-Item-Icon" />
                                6486 E. Main Street, Reynoldsburg, Ohio
                            </Nav.Link>
                            <Nav.Link className="Navigation-Banner-Item" href='mailto:UsJersey@usjersey.com' >
                                <FaIcons.FaEnvelope className="Navigation-Banner-Item-Icon" />
                                UsJersey@usjersey.com
                            </Nav.Link>
                            <div className="Navigation-Banner-Switch-Container">
                                <ThemeToggle />
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Navigation
