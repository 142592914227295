// React imports
import React, { useContext } from 'react';

// Asset imports
import BullseyeUserGuide from '../../assets/documentation/BullseyeUserGuide.pdf'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { IconButton } from '@mui/material'
import * as MdIcons from 'react-icons/md'
import { Container, Row, Col } from 'react-bootstrap'

// Css imports
import '../../stylesheets/BullGridHelp.css'

function BullGridHelp() {
    const { theme } = useContext(ThemeContext);
    return (
        <Container fluid className={`BullGrid-Help-Container ${theme}`}>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <a className='BullGrid-Help-Link' href={BullseyeUserGuide} target='_blank' rel="noreferrer">
                        <h5 className={`BullGrid-Help-Text ${theme}`}>Download the manual here to learn how to get started</h5>
                        <IconButton className={`BullGrid-Help-Button ${theme}`}>
                            <MdIcons.MdLiveHelp />
                        </IconButton>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default BullGridHelp;
