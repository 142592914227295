// React imports
import React from 'react';

// Asset imports
import ThreeGenLogo from '../../assets/images/ThreeGenPedHex.png'

// Css imports
import '../../stylesheets/PedigreeCellRenderer.css'

class PedigreeCellRenderer extends React.Component {
    // Optional - Params for rendering. The same params that are passed to the cellRenderer function.
    // Gets called once before the renderer is used
    init(params) {
        // Get the registration ID to pull the pedigree for
        this.registrationID = params.value;
        this.threeGenClick = params.threeGenClick;

        if (this.registrationID?.length === 17 ?? false) {
            // create the cell
            this.eGui = document.createElement('div');

            // Set the cell contents
            this.eGui.innerHTML = `
                <span>
                    <img src=${ThreeGenLogo} class="btn-three-gen" />
                </span>
            `;

            // get references to the elements we want
            this.threeGenButton = this.eGui.querySelector('.btn-three-gen');

            // Add event listener to three gen button
            this.threeGenEventListener = this.threeGenClick;
            this.threeGenButton.addEventListener('click', (evt) => this.threeGenEventListener(this.registrationID));
        }
    }

    // Mandatory - Return the DOM element of the component, this is what the grid puts into the cell;
    getGui() {
        return this.eGui;
    }

    // Mandatory - Get the cell to refresh. Return true if the refresh succeeded, otherwise return false.
    // If you return false, the grid will remove the component from the DOM and create
    // a new component in its place with the new values.
    // Gets called whenever the cell refreshes
    refresh(params) {
        // return true to tell the grid we refreshed successfully
        return true;
    }
}

export default PedigreeCellRenderer;
