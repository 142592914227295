// React imports
import React, { createContext } from 'react';

// Custom component imports
import UseBullGrid from '../hooks/UseBullGrid'

// Creates the context to be used with all view states for the bull grid
const BullGridContext = createContext();

function BullGridProvider({ children }) {
    // Sets the attributes for use in the context 
    const {
        bullData,
        setBullData,
        isBullDataLoading,
        setIsBullDataLoading,
        bullFilter,
        setBullFilter,
        filteredBullData,
        setFilteredBullData,
        isBullFiltersLoading,
        bullTraitMinMax,
        totalBullCount,
        selectedBullCount,
        handleSliderInputChange,
        handleCheckboxChange,
        resetBullFilter,
        exportBullGrid,
        onBullGridReady,
        handleDisplayColumnCheckboxChange,
        gridDisplayColumns,
        resetColumns,
        isAiOrganizationsLoading,
        pullThreeGenPedigree,
        gridApi,
        filterDefaultsLoading,
        activeFilterPanel,
        handleFilterPanelChanged,
        activeColumnPanel,
        handleColumnPanelChanged,
        appliedFilters,
        resetAppliedFilter,
        handleSelectAllCheckboxChange
    } = UseBullGrid();

    return (
        <BullGridContext.Provider
            value={{
                bullData, 
                setBullData, 
                isBullDataLoading,
                setIsBullDataLoading,
                bullFilter, 
                setBullFilter, 
                filteredBullData,
                setFilteredBullData,
                isBullFiltersLoading,
                bullTraitMinMax,
                totalBullCount,
                selectedBullCount,
                handleSliderInputChange,
                handleCheckboxChange,
                resetBullFilter,
                exportBullGrid,
                onBullGridReady,
                handleDisplayColumnCheckboxChange,
                gridDisplayColumns,
                resetColumns,
                isAiOrganizationsLoading,
                pullThreeGenPedigree,
                gridApi,
                filterDefaultsLoading,
                activeFilterPanel,
                handleFilterPanelChanged,
                appliedFilters,
                resetAppliedFilter,
                handleSelectAllCheckboxChange
            }}>
            {children}
        </BullGridContext.Provider>
    )
}

export { BullGridContext, BullGridProvider };