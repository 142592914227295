// React imports
import { useState, useEffect } from 'react';

// Custom component imports
import { DefaultFilters, DefaultFilterControls } from '../models/BullFilter'
import { getAppliedFilters } from '../logic/AppliedFilterLogic';
import { calculateAverages } from '../logic/AverageLogic';

// Service imports
import * as BullseyeAPI from '../services/BullseyeAPIService'
import * as BullseyeFS from '../services/BullseyeFilterService'
import { DefaultBullColumns } from '../models/BullColumns';
import { CalculateSelectedBullCount } from '../logic/BullCountLogic';

// Context to be used for all state handling for the bull grid
function UseBullGrid() {
    // Holds the view state for all the bull data that was pulled from the API
    const [bullData, setBullData] = useState([]);

    // Holds the view state of the filter being used to filter the bull grid
    const [bullFilter, setBullFilter] = useState(DefaultFilters);

    // Holds the view state of the applied filters for the current state/bull grid
    const [appliedFilters, setAppliedFilters] = useState([]);

    // Holds the view state for the bull data to be shown upon filtering in the bull grid 
    const [filteredBullData, setFilteredBullData] = useState([])

    // Holds the view state of the trait min and max values for each individual trait
    const [bullTraitMinMax, setBullTraitMinMax] = useState([]);

    // Holds the view state of the grid columns that need to be shown to the user
    const [gridDisplayColumns, setGridDisplayColumns] = useState(DefaultBullColumns)

    // Holds the view state of the total number of bulls that have been pulled for the grid
    const [totalBullCount, setTotalBullCount] = useState(0);

    // Holds the view state of the total number of bulls remaining in the list upon filtering
    const [selectedBullCount, setSelectedBullCount] = useState(0);

    // Holds the view state of true if the bull data is loading or hasn't yet loaded otherwise false if loaded in
    const [isBullDataLoading, setIsBullDataLoading] = useState(true);

    // Holds the view state of true if the bull filters are loading or haven't yet loaded otherwise false if loaded in
    const [isBullFiltersLoading, setIsBullFiltersLoading] = useState(true);

    // Holds the view state of true if the AI organizations are loading or haven't yet loaded otherwise false if loaded in
    const [isAiOrganizationsLoading, setIsAiOrganizationsLoading] = useState(true);

    // Holds the view state of true if the filter default values are loading or haven't yet loaded otherwise false if loaded in
    const [filterDefaultsLoading, setFilterDefaultsLoading] = useState(true);

    // Holds the view state of the grid api that is used to export and manipulate the bull grid
    const [gridApi, setGridApi] = useState(null);

    // Holds the view state of the column grid api that is used to manipulate the bull grid columns
    const [gridColumnApi, setGridColumnApi] = useState(null);

    // Holds the view state of the filter panel that was last used
    const [activeFilterPanel, setActiveFilterPanel] = useState(0);

    // Makes the API call to get the AI organizations
    // Makes the API call to get the bull trait min/max values 
    // Makes the API call to get the bull list
    useEffect(() => {   
        // Pull in the AI organizations from the API
        BullseyeAPI.getAIOrganizations().then((res) => {
            var aiSection = {
                sectionHeader: "A.I. Organizations",
                options: [
                ]
            };
            var aiOrganizations = JSON.parse(res.data);
            if (aiOrganizations.length > 0) {
                for (var j = 0; j < aiOrganizations.length; j++) {
                    DefaultFilterControls[0].sections[7].options.push({
                        type: "checkbox",
                        name: `chkAI_${aiOrganizations[j].RandomName.replace(" ", "")}_${aiOrganizations[j].NaabCodeList.replace([" ", "  "], "")}`,
                        label: `${aiOrganizations[j].RandomName} (${aiOrganizations[j].NaabCodeList})`,
                    });
                    Object.defineProperty(DefaultFilters, `chkAI_${aiOrganizations[j].RandomName.replace(" ", "")}_${aiOrganizations[j].NaabCodeList.replace([" ", "  "], "")}`, {
                        value: false,
                        writable: true,
                        enumerable : true, 
                    });
                }
            }
            setIsAiOrganizationsLoading(false);
        })

        // Pull in the bull trait min and max values from the API
        BullseyeAPI.getBullTraitMinMax().then((res) => {
            let deserializedData = JSON.parse(res.data);
            setBullTraitMinMax(deserializedData);
            setIsBullFiltersLoading(false);
        })

        // Pull in the list of bulls from the API
        BullseyeAPI.getBullList().then((res) => {
            let deserializedData = JSON.parse(res.data);
            setBullData(deserializedData);
            setFilteredBullData(deserializedData);
            setIsBullDataLoading(false);
            console.warn(deserializedData)
        });

    }, []);

    // Updates the bull trait sliders with defaults of the min and max values pulled from the API
    useEffect(() => {
        if (bullTraitMinMax[0] != null) {
            // Iterate over the bull filter and set the values
            for (const [key, value] of Object.entries(DefaultFilters)) {
                // Check to make sure that there are max and min values available
                if (bullTraitMinMax[0][`${key}MIN`] !== null && bullTraitMinMax[0][`${key}MAX`]) {
                    bullFilter[key] = [bullTraitMinMax[0][`${key}MIN`], bullTraitMinMax[0][`${key}MAX`]];
                }
            }
            setFilterDefaultsLoading(false);
        }

    }, [bullTraitMinMax])

    // Sets the total bull count state value when the bull data list is reloaded 
    useEffect(() => {
        setTotalBullCount(bullData.length);
    }, [bullData]);

    // Fires the filter bull data method whenever the bull filter is changed/updated 
    useEffect(() => {
        async function fetchData() {
            var filteredData = await BullseyeFS.filterBullData(bullData, bullFilter);
            setFilteredBullData(filteredData);
            return filteredData;
        }

        fetchData();
        setAppliedFilters(getAppliedFilters(bullFilter, DefaultFilters));
    }, [bullFilter]);

    // Sets the selected bull count state value when the filtered bull list data is reloaded 
    useEffect(() => {
        setSelectedBullCount(CalculateSelectedBullCount(filteredBullData));
        
        // Check to see if the filtered data already has the average row if so pop it off
        if (filteredBullData.filter(f => f.RegId == null).length > 0) {
            filteredBullData.pop();
        }
        filteredBullData.push(calculateAverages(filteredBullData));
    }, [filteredBullData,]);

    // Sets the grid selections on bull ready and autosizes all the columns
    function onBullGridReady(params) {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    // Exports the bull grid to a CSV file
    function exportBullGrid() {
        gridApi.exportDataAsCsv();
    }

    // Sets the last active filter panel key to the current panel that is opened
    async function handleFilterPanelChanged(e, panelKey) {
        setActiveFilterPanel(panelKey);
    }

    function resetAppliedFilter(e, filterKey) {
        updateBullFilter(filterKey, DefaultFilters[filterKey]);
    }

    /* Resets the bull filter back to default values */
    function resetBullFilter() {
        setBullFilter(DefaultFilters);
    }

    // Resets the columns filter back to default values
    function resetColumns() {
        setGridDisplayColumns(DefaultBullColumns);
    }

    // Function that is called when the filter is changed to update the bull filter model
    async function handleCheckboxChange(e) {
        updateBullFilter(e.target.name, e.target.checked);
    }

    // Function that is called when the select all/deselect all checkbox is changed
    async function handleSelectAllCheckboxChange(checked, section) {
        let keys = [section.checkboxHeader.name];
        for (let k = 0; k < section['options'].length; ++k) {
            keys.push(section['options'][k].name);
        }
    
        let update = Object.assign({}, bullFilter);
        for (let i = 0; i < keys.length; ++i) {
            let key = keys[i];
            update[key] = checked;
        } 

        setBullFilter({
            ...bullFilter,
            ...update
        });
    }

    // Function that is called when the column display options have changed to update the grid display columns
    async function handleDisplayColumnCheckboxChange(e) {
        setGridDisplayColumns({
            ...gridDisplayColumns,
            [e.target.name]: e.target.checked
        });
    }

    // Function that is called when the filter is changed in order to update the bull filter model
    async function handleSliderInputChange(e, newValue, name) {
        updateBullFilter(name, newValue);
    }

    // Function that updates the bull filter object attribute with the correct value
    async function updateBullFilter(name, value) {
        setBullFilter({
            ...bullFilter,
            [name]: value
        });
    }

    /* Export variables, methods etc to be used as the bull grid context with the bullgrid/bullfilter components */
    return {
        bullData,
        setBullData,
        isBullDataLoading,
        setIsBullDataLoading,
        bullFilter,
        setBullFilter,
        filteredBullData,
        setFilteredBullData,
        isBullFiltersLoading,
        bullTraitMinMax,
        totalBullCount,
        selectedBullCount,
        handleSliderInputChange,
        handleCheckboxChange,
        resetBullFilter,
        exportBullGrid,
        onBullGridReady,
        handleDisplayColumnCheckboxChange,
        gridDisplayColumns,
        resetColumns,
        isAiOrganizationsLoading,
        gridApi,
        filterDefaultsLoading,
        activeFilterPanel,
        handleFilterPanelChanged,
        appliedFilters,
        resetAppliedFilter,
        handleSelectAllCheckboxChange
    }
}

export default UseBullGrid