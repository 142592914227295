// Validates the JerseyMate import form
export default function validateJerseyMateForm(matingGroup) {
    const { herdID, matingGroupName, regIDs } = matingGroup;
    var errors = {};

    if (!herdID.trim()) {
        errors.herdID = '* Required';
    }
    else if (herdID.length > 8) {
        errors.herdID = '* Herd number exceeded maximum length';
    }
    else if (isNaN(herdID)) {
        errors.herdID = '* Herd number must be an 8 digit number';
    }

    if (!matingGroupName.trim()) {
        errors.matingGroupName = '* Required';
    }
    else if (matingGroupName.length > 100) {
        errors.matingGroupName = '* Mating group name exceeded maximum length';
    }

    if (regIDs == null)
    {
        errors.regIDs = '* Please select bull(s) from the grid to import into JerseyMate'
    }
    else if (regIDs.length === 0)
    {
        errors.regIDs = '* Please select bull(s) from the grid to import into JerseyMate'
    }


    return errors;
}