// React imports
import React, { useContext, useState } from 'react';

// Custom component imports
import BullFilter from '../bull-filter/BullFilter';
import BullColumns from '../bull-columns/BullColumns';

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext';
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Skeleton } from '@mui/material'
import { Button, Offcanvas } from 'react-bootstrap';
import * as FaIcons from 'react-icons/fa'
import * as BsIcons from 'react-icons/bs'

// Css imports
import '../../stylesheets/BullGridNav.css'

function BullGridNav() {
    const [showGridFilter, setShowGridFilter] = useState(false);
    const [showGridColumns, setShowGridColumns] = useState(false);
    const handleShowGridFilter = () => setShowGridFilter(true);
    const handleCloseGridFilter = () => setShowGridFilter(false);
    const handleShowGridColumns = () => setShowGridColumns(true);
    const handleCloseGridColumns = () => setShowGridColumns(false);

    const { isBullDataLoading,filterDefaultsLoading } = useContext(BullGridContext);
    const { theme } = useContext(ThemeContext);

    return (
        <div className="BullGridNav-Button-Group">
            {
                isBullDataLoading || filterDefaultsLoading ?
                    <Skeleton variant="rectangular" className={`BullGridNav-Filter-Skeleton ${theme}`} />
                    :
                    <Button className={`BullGridNav-Filter ${theme}`} onClick={handleShowGridFilter}>
                        <FaIcons.FaFilter /> Filter
                    </Button>
            }
            {
                isBullDataLoading ?
                    <Skeleton variant="rectangular" className={`BullGridNav-Columns-Skeleton ${theme}`} />
                    :
                    <Button className={`BullGridNav-Columns ${theme}`} onClick={handleShowGridColumns}>
                        <BsIcons.BsColumnsGap /> Columns
                    </Button>
            }

            <Offcanvas
                className={`BullGridNav-OffCanvas ${theme}`}
                show={showGridFilter}
                onHide={handleCloseGridFilter}
                placement="end" >
                <Offcanvas.Header className={`BullGridNav-OffCanvas-Header ${theme}`} closeButton>
                    <Offcanvas.Title className={`BullGridNav-OffCanvas-Header-Title ${theme}`}>
                        <FaIcons.FaFilter className='BullGridNav-OffCanvas-Header-Icon' />
                        Filter
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={`BullGridNav-OffCanvas-Body ${theme}`}>
                    <BullFilter />
                </Offcanvas.Body>
            </Offcanvas>

            <Offcanvas
                className={`BullGridNav-OffCanvas ${theme}`}
                show={showGridColumns}
                onHide={handleCloseGridColumns}
                placement='end' >
                <Offcanvas.Header className={`BullGridNav-OffCanvas-Header ${theme}`} closeButton>
                    <Offcanvas.Title className={`BullGridNav-OffCanvas-Header-Title ${theme}`}>
                        <BsIcons.BsColumnsGap className='BullGridNav-OffCanvas-Header-Icon' />
                        Columns
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className={`BullGridNav-OffCanvas-Body ${theme}`}>
                    <BullColumns />
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}

export default BullGridNav;
