// React imports
import React, { useContext } from 'react'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Container, Row, Col } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import * as MdIcons from 'react-icons/md'

// Asset imports
import BullseyeLogo from '../../assets/images/BullseyeLogo.png'

// Css imports
import '../../stylesheets/Footer.css'

function Footer() {
    const { theme } = useContext(ThemeContext);

    return (
        <Container fluid className={`Footer-Container ${theme}`}>
            <Row>
                <Col xs="12" sm="12" md="3" lg="3" xl="3">
                    <Row>
                        <Col>
                            <a href="#" target="_self" rel="noreferrer">
                                <img className='Footer-App-Logo' src={BullseyeLogo} alt="Bullseye Logo" />
                                <h2 className='Footer-App-Name'>Bullseye</h2>
                            </a>
                            <p className={`Footer-App-About ${theme}`}>
                                Backed by genetic testing results, we offer some of the most accurate information in the industry. When combined with our JerseyMate mating application it is a combination that cannot be ignored
                                when looking for tools to improve your herd genetics.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={`Footer-SocialIcon-Wrapper ${theme}`}>
                                <a className={`Footer-SocialIcon-Link ${theme}`} href='https://www.facebook.com/USJersey' target="_blank" rel="noreferrer">
                                    <FaIcons.FaFacebookF className={`Footer-SocialIcon-Icon ${theme}`} />
                                </a>
                            </div>
                            <div className={`Footer-SocialIcon-Wrapper ${theme}`}>
                                <a className={`Footer-SocialIcon-Link ${theme}`} href='https://www.instagram.com/usjerseyjournal/' target="_blank" rel="noreferrer">
                                    <FaIcons.FaInstagram className={`Footer-SocialIcon-Icon ${theme}`} />
                                </a>
                            </div>
                            <div className={`Footer-SocialIcon-Wrapper ${theme}`}>
                                <a className={`Footer-SocialIcon-Link ${theme}`} href='https://www.youtube.com/channel/UCt3UAy-Ca1Cjm4769W2fVOg' target="_blank" rel="noreferrer">
                                    <FaIcons.FaYoutube className={`Footer-SocialIcon-Icon ${theme}`} />
                                </a>
                            </div>  
                        </Col>
                    </Row>
                </Col>
                <Col xs="5" sm="5" md="3" lg="3" xl="3">
                    <div className={`Footer-Link-Wrapper ${theme}`}>
                        <h5>Quick Links</h5>
                        <div className={`Footer-Link-Separator ${theme}`}></div>
                        <ul className='Footer-Link-List'>
                            <li className='Footer-Link-Item'>
                                <a href='https://infojersey.usjersey.com/Secure2/' target='_blank' rel="noreferrer" className={`Footer-Link-Link ${theme}`} >InfoJersey</a>
                            </li>
                            <li className='Footer-Link-Item'>
                                <a href="https://infojersey.usjersey.com/JerseyTagStore" target='_blank' rel="noreferrer" className={`Footer-Link-Link ${theme}`}>Jersey Tags</a>
                            </li>
                            <li className='Footer-Link-Item'>
                                <a href='https://greenbook.usjersey.com/' target='_blank' rel="noreferrer" className={`Footer-Link-Link ${theme}`}>GreenBook</a>
                            </li>
                            <li className='Footer-Link-Item'>
                                <a href="https://usjerseyjournal.com" target='_blank' rel="noreferrer" className={`Footer-Link-Link ${theme}`}>Jersey Journal</a>
                            </li>
                            <li className='Footer-Link-Item'>
                                <a href="https://jerseylearningcenter.usjerseyjournal.com/" target='_blank' rel="noreferrer" className={`Footer-Link-Link ${theme}`}>Learning Center</a>
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs="7" sm="7" md="3" lg="3" xl="3">
                    <div className={`Footer-Contact-Wrapper ${theme}`}>
                        <h5>Contact us</h5>
                        <div className={`Footer-Contact-Separator ${theme}`}></div>
                        <ul className='Footer-Contact-List'>
                            <li className={`Footer-Contact-Item ${theme}`}>
                                <MdIcons.MdEmail className={`Footer-Contact-Item-Icon ${theme}`} />
                                <a href="mailto:usjersey.com" rel="noreferrer">
                                    Usjersey@usjersey.com
                                </a>
                            </li>
                            <li className={`Footer-Contact-Item ${theme}`}>
                                <MdIcons.MdLocalPhone className={`Footer-Contact-Item-Icon ${theme}`} />
                                <a href="tel:6148613636" rel="noreferrer">
                                    (614) 861-3636
                                </a>
                            </li>
                            <li className={`Footer-Contact-Item ${theme}`}>
                                <MdIcons.MdDocumentScanner className={`Footer-Contact-Item-Icon ${theme}`} />
                                <a href="tel:6148618040" rel="noreferrer">
                                    (614) 861-8040
                                </a>
                            </li>
                            <li className={`Footer-Contact-Item ${theme}`}>
                                <MdIcons.MdOutlineMap className={`Footer-Contact-Item-Icon ${theme}`} />
                                6486 East Main Street, Reynoldsburg, Ohio 43068
                            </li>
                        </ul>
                    </div>
                </Col>
                <Col xs="12" sm="12" md="3" lg="3" xl="3">
                    <div className={`Footer-WorkingHours-Wrapper ${theme}`}>
                        <h5>Working Hours</h5>
                        <div className={`Footer-WorkingHours-Separator ${theme}`}></div>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Sunday:</span>
                            Closed
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Monday:</span>
                            8AM - 4:30PM
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Tuesday:</span>
                            8AM - 4:30PM
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Wednesday:</span>
                            8AM - 4:30PM
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Thursday:</span>
                            8AM - 4:30PM
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Friday:</span>
                            8AM - 4:30PM
                        </li>
                        <li className='Footer-WorkingHours-ListItem'>
                            <span>Saturday:</span>
                            Closed
                        </li>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer
