// React imports
import React, { useContext } from 'react'

// Context imports
import { JerseyMateFormContext } from '../../contexts/JerseyMateFormContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Dialog, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material'
import * as BsIcons from 'react-icons/bs'

// Css imports
import '../../stylesheets/JerseyMateFormSuccess.css'

function JerseyMateFormSuccess() {
  const { showSuccessForm, closeSuccessForm } = useContext(JerseyMateFormContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Dialog className={`JerseyMateFormSuccess ${theme}`} open={showSuccessForm} onClose={closeSuccessForm}>
      <DialogContent className={`JerseyMateFormSuccess-DialogContent ${theme}`}>
        <DialogContentText className='JerseyMateFormSuccess-DialogContentText'>
          <BsIcons.BsCheck2Circle className='JerseyMateFormSuccess-CheckIcon' />
          Success - Bulls successfully imported into mating group!
        </DialogContentText>
      </DialogContent>
      <DialogActions className={`JerseyMateFormSuccess-DialogActions ${theme}`}>
        <Button onClick={closeSuccessForm}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  )
}

export default JerseyMateFormSuccess