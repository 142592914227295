// React Imports
import React, { useContext } from 'react'

// Context imports
import { JerseyMateFormContext } from '../../contexts/JerseyMateFormContext'

// Custom component imports
import Loader from '../base/Loader';

// CSS Imports
import JerseyMateForm from './JerseyMateForm'
import JerseyMateFormFailed from './JerseyMateFormFailed';
import JerseyMateFormSuccess from './JerseyMateFormSuccess'

function JerseyMateDialog() {
    const { showLoading } = useContext(JerseyMateFormContext);

    return (
        <>
            <Loader show={showLoading} />
            <JerseyMateForm />
            <JerseyMateFormSuccess />
            <JerseyMateFormFailed />
        </>
    )
}

export default JerseyMateDialog;