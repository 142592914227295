/**
 * Filters the provided bull data using the selected bull filter options by the user
 * @param {Array holding each bulls data, Bullseye bull list} bullData 
 * @param {Model holding the user selected filterable values} bullFilter 
 * @returns {The provided bull data filtered using the selected options provided through the bullFilter}
 */
export const filterBullData = async (bullData, bullFilter) => {
    var data = bullData;

    var recordedStatusValues = await getRecordedStatusSelections(bullFilter);
    var generationCountValues = await getGenerationCountSelections(bullFilter);
    var polledStatusValues = await getPolledStatusSelections(bullFilter);
    var betaCaseinValues = await getBetaCaseinSelections(bullFilter);
    var breedEvaluationGroupValues = await getBreedEvaluationGroupSelections(bullFilter);
    var aiOrganizationValues = await getAiOrganizationSelections(bullFilter);
    var aiStatusValues = await getAiStatusSelections(bullFilter);
    var jh1Values = await getJh1Selections(bullFilter);
    var jnsValues = await getJnsSelections(bullFilter);

    if (recordedStatusValues.length > 0 && generationCountValues.length > 0) {
        data = data.filter(item => recordedStatusValues.some(rs => item["RecordedStatus"] == rs || (rs == 'JXFREE' && item['GenJep'] == 0 || item['GenJep'] == null)) && (generationCountValues.some(gc => item["GenCount"] == gc || (gc == 6 && item["GenCount"] >= 6)) || recordedStatusValues.some(rs => rs == 'HR' && item['GenCount'] == null)));
    }
    else if (recordedStatusValues.length > 0) {
        data = data.filter(item => recordedStatusValues.some(rs => item["RecordedStatus"] == rs || (rs == 'JXFREE' && item['GenJep'] == 0 || item['GenJep'] == null)));
    }
    else if (generationCountValues.length > 0) {
        data = data.filter(item => generationCountValues.some(gc => item["GenCount"] == gc || (gc == 6 && item["GenCount"] >= 6)));
    }

    if (polledStatusValues.length > 0) {
        data = data.filter(item => polledStatusValues.some(p => item["Polled"] == p));
    }

    if (betaCaseinValues.length > 0) {
        data = data.filter(item => betaCaseinValues.some(bc => item["BetaCaseinA2"] == bc));
    }

    if (breedEvaluationGroupValues.length > 0) {
        data = data.filter(item => breedEvaluationGroupValues.some(be => item["BreedGroup"] == be));
    }

    if (aiOrganizationValues.length > 0) {
        data = data.filter(item => aiOrganizationValues.some(ai => item["AiOrganization"] == ai));
    }

    if (aiStatusValues.length > 0) {
        data = data.filter(item => aiStatusValues.some(ai => item["AiStatus"] == ai));
    }

    if (jh1Values.length > 0) {
        data = data.filter(item => jh1Values.some(jh1 => item["Jh1"] == jh1));
    }

    if (jnsValues.length > 0) {
        data = data.filter(item => jnsValues.some(jns => item["Jns"] == jns));
    }

    data = data.filter(item =>
        (
            (item["Efi"] >= bullFilter.Efi[0] && item["Efi"] <= bullFilter.Efi[1])
            || bullFilter.Efi == null) &&
        (
            (item["Bbr"] >= bullFilter.Bbr[0] && item["Bbr"] <= bullFilter.Bbr[1])
            || bullFilter.Bbr == null) &&
        (
            (item["Jpi"] >= bullFilter.Jpi[0] && item["Jpi"] <= bullFilter.Jpi[1])
            || bullFilter.Jpi == null) &&
        (
            (item["CFP"] >= bullFilter.Cfp[0] && item["CFP"] <= bullFilter.Cfp[1]) 
            || bullFilter.Cfp == null) &&
        (
            (item["PtaMilk"] >= bullFilter.PtaMilk[0] && item["PtaMilk"] <= bullFilter.PtaMilk[1])
            || bullFilter.PtaMilk == null) &&
        (
            (item["PtaFatPercent"] >= bullFilter.PtaFatPercent[0] && item["PtaFatPercent"] <= bullFilter.PtaFatPercent[1])
            || bullFilter.PtaFatPercent == null) &&
        (
            (item["PtaFat"] >= bullFilter.PtaFat[0] && item["PtaFat"] <= bullFilter.PtaFat[1])
            || bullFilter.PtaFat == null) &&
        (
            (item["PtaProteinPercent"] >= bullFilter.PtaProteinPercent[0] && item["PtaProteinPercent"] <= bullFilter.PtaProteinPercent[1])
            || bullFilter.PtaProteinPercent == null) &&
        (
            (item["PtaProtein"] >= bullFilter.PtaProtein[0] && item["PtaProtein"] <= bullFilter.PtaProtein[1])
            || bullFilter.PtaProtein == null) &&
        (
            (item["NetMerit"] >= bullFilter.NetMerit[0] && item["NetMerit"] <= bullFilter.NetMerit[1])
            || bullFilter.NetMerit == null) &&
        (
            (item["CheeseMerit"] >= bullFilter.CheeseMerit[0] && item["CheeseMerit"] <= bullFilter.CheeseMerit[1])
            || bullFilter.CheeseMerit == null) &&
        (
            (item["FluidMerit"] >= bullFilter.FluidMerit[0] && item["FluidMerit"] <= bullFilter.FluidMerit[1])
            || bullFilter.FluidMerit == null) &&
        (
            (item["GrazingMerit"] >= bullFilter.GrazingMerit[0] && item["GrazingMerit"] <= bullFilter.GrazingMerit[1])
            || bullFilter.GrazingMerit == null) &&
        (
            (item["RelProtein"] >= bullFilter.RelProtein[0] && item["RelProtein"] <= bullFilter.RelProtein[1])
            || bullFilter.RelProtein == null)
    );

    data = data.filter(item =>
        (
            (item["PtaForeUdder"] >= bullFilter.PtaForeUdder[0] && item["PtaForeUdder"] <= bullFilter.PtaForeUdder[1])
            || bullFilter.PtaForeUdder == null) &&
        (
            (item["PtaRearUdderHeight"] >= bullFilter.PtaRearUdderHeight[0] && item["PtaRearUdderHeight"] <= bullFilter.PtaRearUdderHeight[1])
            || bullFilter.PtaRearUdderHeight == null) &&
        (
            (item["PtaRearUdderWidth"] >= bullFilter.PtaRearUdderWidth[0] && item["PtaRearUdderWidth"] <= bullFilter.PtaRearUdderWidth[1])
            || bullFilter.PtaRearUdderWidth == null) &&
        (
            (item["PtaUdderCleft"] >= bullFilter.PtaUdderCleft[0] && item["PtaUdderCleft"] <= bullFilter.PtaUdderCleft[1])
            || bullFilter.PtaUdderCleft == null) &&
        (
            (item["PtaUdderDepth"] >= bullFilter.PtaUdderDepth[0] && item["PtaUdderDepth"] <= bullFilter.PtaUdderDepth[1])
            || bullFilter.PtaUdderDepth == null) &&
        (
            (item["PtaTeatPlacement"] >= bullFilter.PtaTeatPlacement[0] && item["PtaTeatPlacement"] <= bullFilter.PtaTeatPlacement[1])
            || bullFilter.PtaTeatPlacement == null) &&
        (
            (item["PtaTeatLength"] >= bullFilter.PtaTeatLength[0] && item["PtaTeatLength"] <= bullFilter.PtaTeatLength[1])
            || bullFilter.PtaTeatLength == null) &&
        (
            (item["PtaRtr"] >= bullFilter.PtaRtr[0] && item["PtaRtr"] <= bullFilter.PtaRtr[1])
            || bullFilter.PtaRtr == null) &&
        (
            (item["PtaRts"] >= bullFilter.PtaRts[0] && item["PtaRts"] <= bullFilter.PtaRts[1])
            || bullFilter.PtaRts == null) &&
        (
            (item["Jui"] >= bullFilter.Jui[0] && item["Jui"] <= bullFilter.Jui[1])
            || bullFilter.Jui == null)
    );

    data = data.filter(item =>
        (
            (item["PtaFinalScore"] >= bullFilter.PtaFinalScore[0] && item["PtaFinalScore"] <= bullFilter.PtaFinalScore[1])
            || bullFilter.PtaFinalScore == null) &&
        (
            (item["PtaStature"] >= bullFilter.PtaStature[0] && item["PtaStature"] <= bullFilter.PtaStature[1])
            || bullFilter.PtaStature == null) &&
        (
            (item["PtaStrength"] >= bullFilter.PtaStrength[0] && item["PtaStrength"] <= bullFilter.PtaStrength[1])
            || bullFilter.PtaStrength == null) &&
        (
            (item["PtaDairyForm"] >= bullFilter.PtaDairyForm[0] && item["PtaDairyForm"] <= bullFilter.PtaDairyForm[1])
            || bullFilter.PtaDairyForm == null) &&
        (
            (item["PtaRumpAngle"] >= bullFilter.PtaRumpAngle[0] && item["PtaRumpAngle"] <= bullFilter.PtaRumpAngle[1])
            || bullFilter.PtaRumpAngle == null) &&
        (
            (item["PtaRumpWidth"] >= bullFilter.PtaThurlWidth[0] && item["PtaRumpWidth"] <= bullFilter.PtaThurlWidth[1])
            || bullFilter.PtaThurlWidth == null) &&
        (
            (item["PtaRearLegs"] >= bullFilter.PtaRearLegs[0] && item["PtaRearLegs"] <= bullFilter.PtaRearLegs[1])
            || bullFilter.PtaRearLegs == null) &&
        (
            (item["PtaFootAngle"] >= bullFilter.PtaFootAngle[0] && item["PtaFootAngle"] <= bullFilter.PtaFootAngle[1])
            || bullFilter.PtaFootAngle == null)
    );

    data = data.filter(item =>
        (
            (item["PtaDpr"] >= bullFilter.PtaDpr[0] && item["PtaDpr"] <= bullFilter.PtaDpr[1])
            || bullFilter.PtaDpr == null) &&
        (
            (item["PtaHcr"] >= bullFilter.PtaHcr[0] && item["PtaHcr"] <= bullFilter.PtaHcr[1])
            || bullFilter.PtaHcr == null) &&
        (
            (item["PtaCcr"] >= bullFilter.PtaCcr[0] && item["PtaCcr"] <= bullFilter.PtaCcr[1])
            || bullFilter.PtaCcr == null) &&
        (
            (item["PtaScs"] >= bullFilter.PtaScs[0] && item["PtaScs"] <= bullFilter.PtaScs[1])
            || bullFilter.PtaScs == null) &&
        (
            (item["PtaProdLife"] >= bullFilter.PtaProdLife[0] && item["PtaProdLife"] <= bullFilter.PtaProdLife[1])
            || bullFilter.PtaProdLife == null) &&
        (
            (item["PtaLiv"] >= bullFilter.PtaLiv[0] && item["PtaLiv"] <= bullFilter.PtaLiv[1])
            || bullFilter.PtaLiv == null)
    );

    data = data.filter(item =>
        (
            (item["PtaMfv"] >= bullFilter.PtaMfv[0] && item["PtaMfv"] <= bullFilter.PtaMfv[1])
            || bullFilter.PtaMfv == null) &&
        (
            (item["PtaDab"] >= bullFilter.PtaDab[0] && item["PtaDab"] <= bullFilter.PtaDab[1])
            || bullFilter.PtaDab == null) &&
        (
            (item["PtaKet"] >= bullFilter.PtaKet[0] && item["PtaKet"] <= bullFilter.PtaKet[1])
            || bullFilter.PtaKet == null) &&
        (
            (item["PtaMas"] >= bullFilter.PtaMas[0] && item["PtaMas"] <= bullFilter.PtaMas[1])
            || bullFilter.PtaMas == null) &&
        (
            (item["PtaMet"] >= bullFilter.PtaMet[0] && item["PtaMet"] <= bullFilter.PtaMet[1])
            || bullFilter.PtaMet == null) &&
        (
            (item["PtaRpl"] >= bullFilter.PtaRpl[0] && item["PtaRpl"] <= bullFilter.PtaRpl[1])
            || bullFilter.PtaRpl == null) &&
        (
            (item["Hti"] >= bullFilter.Hti[0] && item["Hti"] <= bullFilter.Hti[1])
            || bullFilter.Hti == null)
    );

    return data;
}

// Gets the recorded status values that are linked to the checkboxes selected, converts the checkbox boolean to the filterable bull list values
async function getRecordedStatusSelections(bullFilter) {
    var recordedStatusValues = [];

    if (bullFilter.chkHR == true) {
        recordedStatusValues.push('HR');
    }
    if (bullFilter.chkJX == true) {
        recordedStatusValues.push('JX');
    }
    if (bullFilter.chkJXFree == true) {
        recordedStatusValues.push('JXFREE')
    }

    return recordedStatusValues;
}

async function getGenerationCountSelections(bullFilter) {
    var generationCounts = [];

    if (bullFilter.chkGen2 == true) {
        generationCounts.push('2');
    }
    if (bullFilter.chkGen3 == true) {
        generationCounts.push('3');
    }
    if (bullFilter.chkGen4 == true) {
        generationCounts.push('4');
    }
    if (bullFilter.chkGen5 == true) {
        generationCounts.push('5');
    }
    if (bullFilter.chkGen6 == true) {
        generationCounts.push('6');
    }

    return generationCounts;
}

// Gets the polled status values that are linked to the checkboxes selected for the polled status code, converts the checkbox boolean to the filterable bull list values
async function getPolledStatusSelections(bullFilter) {
    var polledStatusValues = [];

    if (bullFilter.chkNotPolled == true) {
        polledStatusValues.push('', null);
    }
    if (bullFilter.chkPolled == true) {
        polledStatusValues.push('P');
    }
    if (bullFilter.chkHomozygusPolled == true) {
        polledStatusValues.push('PP');
    }

    return polledStatusValues;
}

// Gets the Beta Casein A2 status values that are linked to the checkboxes for the BetaCaseinA2 status, converts the checkbox boolean to the filterable bull list values
async function getBetaCaseinSelections(bullFilter) {
    var betaCaseinValues = [];

    if (bullFilter.chkA2A2 == true) {
        betaCaseinValues.push('A2/A2');
    }

    if (bullFilter.chkA1A2 == true) {
        betaCaseinValues.push('A1/A2');
    }

    if (bullFilter.chkA1A1 == true) {
        betaCaseinValues.push('A1/A1');
    }

    if (bullFilter.chkBcUntested == true) {
        betaCaseinValues.push('', null);
    }

    return betaCaseinValues;
}

// Gets the breed evaluation group values that are linked to the checkboxes for the breed group, converts the checkbox boolean to the filterable bull list values
async function getBreedEvaluationGroupSelections(bullFilter) {
    var breedEvaluationGroupValues = [];

    if (bullFilter.chkSingleBreed == true) {
        breedEvaluationGroupValues.push('S');
    }
    if (bullFilter.chkMultiBreed == true) {
        breedEvaluationGroupValues.push('M');
    }

    return breedEvaluationGroupValues;
}

// Gets the Ai status values that are linked to the checkboxes for the AI Status code, converts the checkbox boolean to the filterable bull list values
async function getAiStatusSelections(bullFilter) {
    var aiStatusValues = [];

    if (bullFilter.chkAiActive == true) {
        aiStatusValues.push('A');
    }
    if (bullFilter.chkAiForeign == true) {
        aiStatusValues.push('F');
    }
    if (bullFilter.chkAiGenomic == true) {
        aiStatusValues.push('G');
    }

    return aiStatusValues;
}

// Gets the JH1 selection values that are linked to the checkboxes for the JH1 status, converts the checkbox boolean to the filterable bull list values
async function getJh1Selections(bullFilter) {
    let jh1Values = [];

    if (bullFilter.chkJh1Carrier == true) {
        jh1Values.push('C');
    }
    if (bullFilter.chkJh1Free == true) {
        jh1Values.push('F');
    }
    if (bullFilter.chkJh1Untested == true) {
        jh1Values.push('', null);
    }

    return jh1Values;
}

// Gets the JNS selection values that are linked to the checkboxes for the JNS status, converts the checkbox boolean to the filterable bull list values
async function getJnsSelections(bullFilter) {
    var jnsValues = [];

    if (bullFilter.chkJnsCarrier == true) {
        jnsValues.push('JNSC');
    }
    if (bullFilter.chkJnsTestedFree == true) {
        jnsValues.push('JNS-TF');
    }
    if (bullFilter.chkJnsPedigreeFree == true) {
        jnsValues.push('JNS-PF');
    }
    if (bullFilter.chkJnsUntested == true) {
        jnsValues.push('', null);
    }

    return jnsValues;
}

// Gets the AI Organization selection values that are linked to the checkboxes for the AI organizations, converts the checkbox booleans to filterable values
async function getAiOrganizationSelections(bullFilter) {
    var aiOrganizationIDValues = [];
    for (const [key, value] of Object.entries(bullFilter)) {
        if (key.includes("chkAI_") && !key.includes("SelectAll") && value == true) {
             // Split the values from the chkAI_ checkbox name and take the values which should always be in position 2 in the index
             var commaSeperatedNumbers = key.split("_")[2];

             // Split the string array value on the commas if there are multiple values and Convert all seperated string values to a number array
             var numbers = commaSeperatedNumbers.split(",").map(Number);

             // Push the numbers/ai organization IDS to the array for filtering
             aiOrganizationIDValues.push.apply(aiOrganizationIDValues, numbers);
        }
    }
    return aiOrganizationIDValues;
}