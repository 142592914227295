// React Imports
import { useState } from "react";

function UseTheme(startingTheme = 'dark') {
    const [theme, setTheme] = useState(startingTheme);

    function ValidateTheme(themeValue) {
        if(themeValue === 'light') {
            setTheme('light');
        }
        else {
            setTheme('dark');
        }
    }

    return {
        theme,
        setTheme: ValidateTheme,
    }
}

export default UseTheme;