// React imports
import React from 'react'

// Package component
import { Slider, Typography } from '@mui/material'
import { Container, Row, Col } from 'react-bootstrap'

// CSS Imports
import '../../stylesheets/BullSlider.css'

function BullSlider({ sliderChange, header, name, value, min, max, step }) {
    return (
        <Container fluid className='mt-2 px-4 py-2'>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-0'>
                    <Typography className='BullSlider-Header'>{header}</Typography>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <Slider className="BullSlider-Slider"
                        key={`${name}-${value}`}
                        id={name}
                        name={name}
                        onChangeCommitted={(e, newValue) => {sliderChange(e, newValue, name)}}
                        min={min}
                        max={max}
                        step={step}
                        defaultValue={value}
                        valueLabelDisplay="auto"
                        disableSwap 
                        color="primary">
                    </Slider>
                </Col>
            </Row>
        </Container>
    )
}

export default BullSlider
