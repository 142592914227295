// React imports
import React, { useContext } from 'react'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Packaged component imports
import { Container, Row, Col, Button } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'
import * as AiIcons from 'react-icons/ai'
import * as CgIcons from 'react-icons/cg'
import * as BiIcons from 'react-icons/bi'
import * as GiIcons from 'react-icons/gi'

// Css imports
import '../../stylesheets/OrganizationBio.css'

function OrganizationBio() {
    const { theme } = useContext(ThemeContext);

    return (
        <Container id="Organization-Bio" fluid className={`OrganizationBio-Container ${theme}`}>
            <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <h5 className={`OrganizationBio-SubHeader ${theme}`}>
                        About Us +
                    </h5>
                    <h1 className={`OrganizationBio-Header ${theme}`}>American Jersey Cattle Association, the oldest dairy breed association in the U.S.</h1>
                    <p className={`OrganizationBio-SubText ${theme}`}>
                        The American Jersey Cattle Association compiles and maintains animal identification and performance data on Jersey
                        cattle and provides services that support genetic improvement and greater profitability through increasing the
                        value of and demand for Registered Jersey™ cattle and genetics, and Jersey milk and milk products.
                    </p>
                    <p className={`OrganizationBio-SubText ${theme}`}>
                        The association was organized in 1868 and is the oldest dairy breed association in the United States.
                        The American Jersey Cattle Association (UsJersey) has 2,107 active members as of May 2016 and provides
                        services to over 3,000 Jersey dairy farmers each year. The membership elects a 12-person Board of Directors
                        and President.
                    </p>
                    <Button className={`OrganizationBio-Button ${theme}`} href="https://www.usjersey.com/" target='_blank'>
                        More About Us
                        <FaIcons.FaArrowRight />
                    </Button>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div className="OrganizationBio-Grid">
                        <div className="OrganizationBio-Grid-Column">
                            <div className={`OrganizationBio-Grid-Panel ${theme}`}>
                                <div className="OrganizationBio-Grid-Panel-Header-Container">
                                    <AiIcons.AiOutlineTag className={`OrganizationBio-Grid-Panel-Icon ${theme}`} />
                                    <h5 className={`OrganizationBio-Grid-Panel-Header ${theme}`}>Animal identification +</h5>
                                </div>
                                <p className={`OrganizationBio-Grid-Panel-Text ${theme}`}>
                                    For the power to make matings that maximize lifetime profitability while controlling inbreeding, you need ancestry.
                                    For that, you need the services delivered by the American Jersey Cattle Association.
                                </p>
                            </div>
                            <div className={`OrganizationBio-Grid-Panel ${theme}`}>
                                <div className="OrganizationBio-Grid-Panel-Header-Container">
                                    <BiIcons.BiDna className={`OrganizationBio-Grid-Panel-Icon ${theme}`} />
                                    <h5 className={`OrganizationBio-Grid-Panel-Header ${theme}`}>Genetics +</h5>
                                </div>
                                <p className={`OrganizationBio-Grid-Panel-Text ${theme}`}>
                                    While the AJCA's Constitutional mission is "to improve and promote the Jersey breed," the
                                    objective is to make owning Jerseys more profitable than owning any other breed of dairy cattle.
                                    Today's record-breaking levels of Jersey production and owner satisfaction are, in large part, a
                                    function of the Association's genetic improvement tools and programs.
                                </p>
                            </div>
                        </div>
                        <div className="OrganizationBio-Grid-Column">
                            <div className={`OrganizationBio-Grid-Panel ${theme}`}>
                                <div className='OrganizationBio-Grid-Panel-Header-Container'>
                                    <CgIcons.CgPerformance className={`OrganizationBio-Grid-Panel-Icon ${theme}`} />
                                    <h5 className={`OrganizationBio-Grid-Panel-Header ${theme}`}>REAP: Performance Counts +</h5>
                                </div>
                                <p className={`OrganizationBio-Grid-Panel-Text ${theme}`}>
                                    REAP delivers the complete service package of registration, Equity milk marketing support,
                                    functional type appraisal, and performance testing—for less than you would pay by enrolling
                                    in each separately. It’s one-stop service at its finest—and most profitable.
                                </p>
                            </div>
                            <div className={`OrganizationBio-Grid-Panel ${theme}`}>
                                <div className='OrganizationBio-Grid-Panel-Header-Container'>
                                    <GiIcons.GiGavel className={`OrganizationBio-Grid-Panel-Icon ${theme}`} />
                                    <h5 className={`OrganizationBio-Grid-Panel-Header ${theme}`}>Marketing +</h5>
                                </div>
                                <p className={`OrganizationBio-Grid-Panel-Text ${theme}`}>
                                    The marketing arm of UsJersey is NAJ (National All-Jersey Inc) and its subsidiary All-Jersey Sales Corporation.
                                    Our staff is located around the country and ready to find the best animals to fit your needs, so let us take you ringside
                                    with secure onsite, live and internet bidding services.
                                </p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>

    )
}

export default OrganizationBio
