// React imports
import React, { useContext } from 'react'

// Custom component imports
import FilterSection from './FilterSection'

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Accordion } from 'react-bootstrap'

// Css imports
import '../../stylesheets/BullFilter.css'

function FilterPanel({ filterPanel, index }) {
    const { handleFilterPanelChanged } = useContext(BullGridContext);
    const { theme } = useContext(ThemeContext);

    return (
        <>
            <Accordion.Item eventKey={index} onClick={(e) => handleFilterPanelChanged(e, index)}>
                <Accordion.Header>
                    {filterPanel.panelHeader}
                </Accordion.Header>
                <Accordion.Body className={`BullFilter-Accordion-Body ${theme}`}>
                    {
                        filterPanel.sections.map((filterSection, index) => {
                            return (
                                <FilterSection filterSection={filterSection} key={index} />
                            )
                        })
                    }
                </Accordion.Body>
            </Accordion.Item>
        </>
    )
}

export default FilterPanel
