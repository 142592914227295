// React imports
import React, { useContext } from 'react'

// Custom component imports
import BullGridHeader from './BullGridHeader'
import BullGridSpeedDial from './BullGridSpeedDial'
import BullGridNav from './BullGridNav'
import BullGridHelp from './BullGridHelp'
import PedigreeCellRenderer from '../cell-renderers/PedigreeCellRenderer'
import AppliedFilters from '../applied-filters/AppliedFilters'

// Logic Imports
import { pullThreeGenPedigree } from '../../logic/PedigreeLogic'

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import { JerseyMateFormProvider } from '../../contexts/JerseyMateFormContext'

// Package component imports
import { Container, Row, Col } from 'react-bootstrap'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Skeleton } from '@mui/material'

// Css imports
import '../../stylesheets/BullGrid.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

function BullGrid() {
    const { filteredBullData, gridApi, gridDisplayColumns, isBullDataLoading, onBullGridReady } = useContext(BullGridContext);
    const { theme } = useContext(ThemeContext);
    const bullGridColumnDefs = [
        {
            field: "RegId",
            headerName: "Pedigree",
            hide: false,
            cellRenderer: PedigreeCellRenderer,
            width: 100,
        },
        {
            field: "RegId",
            headerName: "Registration ID",
            hide: !gridDisplayColumns.chkDisplay_RegistrationID,
            cellClass: "ag-cell-left",
            width: 150,
        },
        {
            field: "FullName",
            headerName: "Full Name",
            hide: !gridDisplayColumns.chkDisplay_FullName,
            cellClass: "ag-cell-left",
            width: 350,
        },
        {
            field: "NaabCode",
            headerName: "NAAB Number",
            hide: !gridDisplayColumns.chkDisplay_NaabCode,
            cellClass: "ag-cell-left",
            width: 125,
        },
        {
            field: "Jpi",
            headerName: "JPI",
            hide: !gridDisplayColumns.chkDisplay_Jpi,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "CFP",
            headerName: "CFP",
            hide: !gridDisplayColumns.chkDisplay_Cfp,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "ShortName",
            headerName: "Short Name",
            hide: !gridDisplayColumns.chkDisplay_ShortName,
            cellClass: "ag-cell-left",
            width: 150,
        },
        {
            field: "SireRegId",
            headerName: "Sire ID",
            hide: !gridDisplayColumns.chkDisplay_SireRegID,
            cellClass: "ag-cell-left",
            width: 150
        },
        {
            field: "SireShortName",
            headerName: "Sire Short Name",
            hide: !gridDisplayColumns.chkDisplay_SireShortName,
            cellClass: "ag-cell-left",
            width: 150,
        },
        {
            field: "MgsRegId",
            headerName: "MGS ID",
            hide: !gridDisplayColumns.chkDisplay_MgsRegID,
            cellClass: "ag-cell-left",
            width: 150
        },
        {
            field: "MgsShortName",
            headerName: "MGS Short Name",
            hide: !gridDisplayColumns.chkDisplay_MgsShortName,
            cellClass: "ag-cell-left",
            width: 150,
        },
        {
            field: "MggsRegId",
            headerName: "MGGS ID",
            hide: !gridDisplayColumns.chkDisplay_MggsRegID,
            cellClass: "ag-cell-left",
            width: 150
        },
        {
            field: "Polled",
            headerName: "Polled",
            hide: !gridDisplayColumns.chkDisplay_Polled,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "BetaCaseinA2",
            headerName: "Beta Casein A2",
            hide: !gridDisplayColumns.chkDisplay_BetaCaseinA2,
            cellClass: "ag-cell-centered",
            width: 120,
        },
        {
            field: "Efi",
            headerName: "GFI",
            hide: !gridDisplayColumns.chkDisplay_Efi,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "Bbr",
            headerName: "BBR",
            hide: !gridDisplayColumns.chkDisplay_Bbr,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "BreedGroup",
            headerName: "Breed Group",
            hide: !gridDisplayColumns.chkDisplay_BreedGroup,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "AiStatus",
            headerName: "A.I. Status",
            hide: !gridDisplayColumns.chkDisplay_AiStatus,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "AiOrganizationName",
            headerName: "A.I. Organization",
            hide: !gridDisplayColumns.chkDisplay_AiOrganization,
            cellClass: "ag-cell-left",
            width: 175,
        },
        {
            field: "PtaMilk",
            headerName: "PTA Milk",
            hide: !gridDisplayColumns.chkDisplay_PtaMilk,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaFatPercent",
            headerName: "% Fat",
            hide: !gridDisplayColumns.chkDisplay_PtaFatPercent,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaFat",
            headerName: "PTA Fat",
            hide: !gridDisplayColumns.chkDisplay_PtaFat,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaProteinPercent",
            headerName: "% Pro",
            hide: !gridDisplayColumns.chkDisplay_PtaProteinPercent,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaProtein",
            headerName: "PTA Pro",
            hide: !gridDisplayColumns.chkDisplay_PtaProtein,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "NetMerit",
            headerName: "NM$",
            hide: !gridDisplayColumns.chkDisplay_NetMerit,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "CheeseMerit",
            headerName: "CM$",
            hide: !gridDisplayColumns.chkDisplay_CheeseMerit,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "FluidMerit",
            headerName: "FM$",
            hide: !gridDisplayColumns.chkDisplay_FluidMerit,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "GrazingMerit",
            headerName: "GM$",
            hide: !gridDisplayColumns.chkDisplay_GrazingMerit,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "RelProtein",
            headerName: "REL %",
            hide: !gridDisplayColumns.chkDisplay_RelProtein,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "Jui",
            headerName: "JUI",
            hide: !gridDisplayColumns.chkDisplay_Jui,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaForeUdder",
            headerName: "PTA FU",
            hide: !gridDisplayColumns.chkDisplay_PtaForeUdder,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRearUdderHeight",
            headerName: "PTA RUH",
            hide: !gridDisplayColumns.chkDisplay_PtaRearUdderHeight,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRearUdderWidth",
            headerName: "PTA RUW",
            hide: !gridDisplayColumns.chkDisplay_PtaRearUdderWidth,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaUdderCleft",
            headerName: "PTA UC",
            hide: !gridDisplayColumns.chkDisplay_PtaUdderCleft,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaUdderDepth",
            headerName: "PTA UD",
            hide: !gridDisplayColumns.chkDisplay_PtaUdderDepth,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaTeatPlacement",
            headerName: "PTA TP",
            hide: !gridDisplayColumns.chkDisplay_PtaTeatPlacement,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaTeatLength",
            headerName: "PTA TL",
            hide: !gridDisplayColumns.chkDisplay_PtaTeatLength,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRtr",
            headerName: "PTA RTR",
            hide: !gridDisplayColumns.chkDisplay_PtaRtr,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRts",
            headerName: "PTA RTS",
            hide: !gridDisplayColumns.chkDisplay_PtaRts,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaFinalScore",
            headerName: "PTA FS",
            hide: !gridDisplayColumns.chkDisplay_PtaFinalScore,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaStature",
            headerName: "PTA ST",
            hide: !gridDisplayColumns.chkDisplay_PtaStature,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaStrength",
            headerName: "PTA SR",
            hide: !gridDisplayColumns.chkDisplay_PtaStrength,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaDairyForm",
            headerName: "PTA DF",
            hide: !gridDisplayColumns.chkDisplay_PtaDairyForm,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRumpAngle",
            headerName: "PTA RA",
            hide: !gridDisplayColumns.chkDisplay_PtaRumpAngle,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRumpWidth",
            headerName: "PTA RW",
            hide: !gridDisplayColumns.chkDisplay_PtaThurlWidth,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRearLegs",
            headerName: "PTA RL",
            hide: !gridDisplayColumns.chkDisplay_PtaRearLegs,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaFootAngle",
            headerName: "PTA FA",
            hide: !gridDisplayColumns.chkDisplay_PtaFootAngle,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaLiv",
            headerName: "PTA LIV",
            hide: !gridDisplayColumns.chkDisplay_PtaLiv,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaDpr",
            headerName: "PTA DPR",
            hide: !gridDisplayColumns.chkDisplay_PtaDpr,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaHcr",
            headerName: "PTA HCR",
            hide: !gridDisplayColumns.chkDisplay_PtaHcr,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaCcr",
            headerName: "PTA CCR",
            hide: !gridDisplayColumns.chkDisplay_PtaCcr,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaScs",
            headerName: "PTA SCS",
            hide: !gridDisplayColumns.chkDisplay_PtaScs,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaProdLife",
            headerName: "PTA PL",
            hide: !gridDisplayColumns.chkDisplay_PtaProdLife,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "Jh1",
            headerName: "JH1",
            hide: !gridDisplayColumns.chkDisplay_Jh1,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "Jns",
            headerName: "JNS",
            hide: !gridDisplayColumns.chkDisplay_Jns,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "Hti",
            headerName: "HTI",
            hide: !gridDisplayColumns.chkDisplay_Hti,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaMfv",
            headerName: "PTA MFV",
            hide: !gridDisplayColumns.chkDisplay_PtaMfv,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaDab",
            headerName: "PTA DAB",
            hide: !gridDisplayColumns.chkDisplay_PtaDab,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaKet",
            headerName: "PTA KET",
            hide: !gridDisplayColumns.chkDisplay_PtaKet,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaMas",
            headerName: "PTA MAS",
            hide: !gridDisplayColumns.chkDisplay_PtaMas,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaMet",
            headerName: "PTA MET",
            hide: !gridDisplayColumns.chkDisplay_PtaMet,
            cellClass: "ag-cell-centered",
            width: 100,
        },
        {
            field: "PtaRpl",
            headerName: "PTA RPL",
            hide: !gridDisplayColumns.chkDisplay_PtaRpl,
            cellClass: "ag-cell-centered",
            width: 100,
        },
    ]

    return (
        <>
            <Container id="BullseyeGridContainer" fluid className={`BullGrid-Container ${theme}`}>
                <Row>
                    <Col className="BullGrid-Help" xs="12" sm="12" md="12" lg="12" xl="12">
                        <BullGridHelp />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <BullGridHeader />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <Container fluid className={`BullGrid-BullList-Container ${theme}`}>
                            <Row>
                                <Col xs="7" sm="7" md="7" lg="7" xl="7">
                                    <JerseyMateFormProvider gridApi={gridApi}>
                                        <BullGridSpeedDial />
                                    </JerseyMateFormProvider>
                                </Col>
                                <Col xs="5" sm="5" md="5" lg="5" xl="5" className='p-0'>
                                    <BullGridNav />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-2'>
                                    <AppliedFilters />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12" xl="12" className='p-0 mt-3'>
                                    {
                                        isBullDataLoading ?
                                            <Skeleton variant="rectangular" className="BullGrid-Skeleton" />
                                            :
                                            <div id="BullseyeGrid" className={theme === 'light' ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} style={{ width: '100%', height: '600px' }}>
                                                <AgGridReact
                                                    defaultColDef={{
                                                        sortable: true,
                                                        resizable: true,
                                                        filter: false,
                                                    }}
                                                    suppressScrollOnNewData="true"
                                                    suppressDragLeaveHidesColumns="true"
                                                    maintainColumnOrder="true"
                                                    onGridReady={onBullGridReady}
                                                    animateRows={true}
                                                    rowDragManaged={true}
                                                    rowData={filteredBullData}
                                                    rowSelection="multiple"
                                                    rowMultiSelectWithClick>
                                                    {
                                                        bullGridColumnDefs.map((column, index) => {
                                                            return (<AgGridColumn
                                                                width={column.width}
                                                                cellClass={column.cellClass}
                                                                key={index}
                                                                field={column.field}
                                                                headerName={column.headerName}
                                                                hide={column.hide}
                                                                rowDrag={column.rowDrag}
                                                                cellRenderer={column.cellRenderer}
                                                                cellRendererParams={{ threeGenClick: pullThreeGenPedigree }}>
                                                            </AgGridColumn>
                                                            )
                                                        })
                                                    }
                                                </AgGridReact>
                                            </div>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BullGrid
