// React imports
import React, { useContext } from 'react'

// Custom component imports
import BullSlider from '../bull-slider/BullSlider'

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'

// Package component imports
import { Form } from 'react-bootstrap'

// Css imports
import '../../stylesheets/BullFilter.css'

function FilterSection({ filterSection }) {
    const { bullFilter, bullTraitMinMax, handleSliderInputChange, handleCheckboxChange, handleSelectAllCheckboxChange } = useContext(BullGridContext);

    return (
        <section className="BullFilter-Filter-Section">
            <div className="BullFilter-Filter-Section-Header">
                {filterSection.sectionHeader}
                {
                    filterSection.checkboxHeader &&
                    <Form.Check
                        className='BullFilter-FilterSection-SelectAll'
                        type="checkbox"
                        checked={bullFilter[filterSection.checkboxHeader.name]}
                        onChange={(e) => handleSelectAllCheckboxChange(e.target.checked, filterSection)}
                        onClick={(e) => handleSelectAllCheckboxChange(e.target.checked, filterSection)}
                        name={filterSection.checkboxHeader.name}
                        label={filterSection.checkboxHeader.label}
                        key={filterSection.checkboxHeader.id}
                        id={`CheckboxID_${filterSection.checkboxHeader['name']}`} />
                }
            </div>
            <div className="BullFilter-Filter-Section-Seperator"></div>
            <div className="BullFilter-Filter-Section-Content">
                {
                    filterSection.options.map((option, index) => {
                        switch (option.type) {
                            case 'checkbox':
                                return (
                                    <Form.Check
                                        type="checkbox"
                                        onChange={handleCheckboxChange}
                                        onClick={handleCheckboxChange}
                                        checked={bullFilter[option.name]}
                                        name={option.name}
                                        label={option.label}
                                        key={index}
                                        id={`CheckboxID_${option.name}`} />
                                )
                            case 'slider':
                                return (
                                    <BullSlider
                                        key={index}
                                        value={bullFilter[option.name] ?? bullTraitMinMax[0][`${option.name}MIN`]}
                                        sliderChange={handleSliderInputChange}
                                        header={option.header}
                                        name={option.name}
                                        min={bullTraitMinMax[0][`${option.name}MIN`]}
                                        max={bullTraitMinMax[0][`${option.name}MAX`]}
                                        step={option.step}
                                        id={`SlideID_${option.name}`} />
                                )
                            default:
                                return option;
                        }
                    })
                }
            </div>
        </section>
    )



}

export default FilterSection
