// React imports
import React, { useContext } from 'react';

// Custom component imports
import JerseyMateDialog from '../jersey-mate/JerseyMateDialog';

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext'
import { ThemeContext } from '../../contexts/ThemeContext'
import { JerseyMateFormContext } from '../../contexts/JerseyMateFormContext';

// Package component imports
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Skeleton, IconButton, Tooltip } from '@mui/material'
import * as RiIcons from 'react-icons/ri'
import * as GiIcons from 'react-icons/gi'

// Css imports
import '../../stylesheets/BullGridSpeedDial.css'

function BullGridSpeedDial() {
    const { isBullDataLoading, exportBullGrid, gridApi, } = useContext(BullGridContext);
    const { openEntryForm } = useContext(JerseyMateFormContext);
    const { theme } = useContext(ThemeContext);

    function promptJerseyMateForm() {
        //get the selected rows from the grid
        var selectedRegIDs;
        let selectedRows = gridApi.getSelectedNodes();;

        // Check to see if there are any selected rows
        if (selectedRows.length > 0) {
            // get the selected rows data attribute
            let selectedData = selectedRows.map(node => node.data);

            // get the selected registration IDs from the row
            selectedRegIDs = selectedData.map(data => data.RegId);
        }

        openEntryForm(selectedRegIDs);
    }

    return (
        <>
            {
                isBullDataLoading ?
                    <Skeleton className={`BullSpeedDial-Skeleton ${theme}`} variant="circular" />
                    :
                    <>
                        <div className={`BullSpeedDial-Collapsed ${theme}`}>
                            <Tooltip title='Export To Excel'>
                                <IconButton onClick={exportBullGrid} className={`BullSpeedDial-Actions ${theme}`}>
                                    <RiIcons.RiFileExcel2Line />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='JerseyMate'>
                                <IconButton onClick={promptJerseyMateForm} className={`BullSpeedDial-Actions ${theme}`}>
                                    <GiIcons.GiCow />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <SpeedDial className={`BullSpeedDial ${theme}`}
                                ariaLabel='SpeedDial'
                                icon={<SpeedDialIcon />}
                                direction='right'>
                                <SpeedDialAction
                                    className={`BullSpeedDial-Actions ${theme}`}
                                    onClick={exportBullGrid}
                                    icon={<RiIcons.RiFileExcel2Line />}
                                    tooltipTitle="Export To Excel" />
                                <SpeedDialAction
                                    className={`BullSpeedDial-Actions ${theme}`}
                                    icon={<GiIcons.GiCow />}
                                    onClick={promptJerseyMateForm}
                                    tooltipTitle="JerseyMate" />
                            </SpeedDial>
                        </div>
                        <JerseyMateDialog />
                    </>

            }
        </>
    )
}

export default BullGridSpeedDial;
