// Model to be used for filtering the bull list
export const DefaultFilters = {
    chkHR: false,
    chkGen2: false,
    chkGen3: false,
    chkGen4: false,
    chkGen5: false,
    chkGen6: false,
    chkJX: false,
    chkJXFree: false,
    chkNotPolled: false,
    chkPolled: false,
    chkHomozygusPolled: false,
    chkA2A2: false,
    chkA1A1: false,
    chkA1A2: false,
    chkBcUntested: false,
    Efi: null,
    Bbr: null,
    chkSingleBreed: false,
    chkMultiBreed: false,
    chkAiActive: false,
    chkAiForeign: false,
    chkAiGenomic: false,
    Jpi: null,
    Cfp: null,
    PtaMilk: null,
    PtaFatPercent: null,
    PtaFat: null,
    PtaProteinPercent: null,
    PtaProtein: null,
    NetMerit: null,
    CheeseMerit: null,
    FluidMerit: null,
    GrazingMerit: null,
    RelProtein: null,
    PtaForeUdder: null,
    PtaRearUdderHeight: null,
    PtaRearUdderWidth: null,
    PtaUdderCleft: null,
    PtaUdderDepth: null,
    PtaTeatPlacement: null,
    PtaTeatLength: null,
    PtaRtr: null,
    PtaRts: null,
    Jui: null,
    PtaFinalScore: null,
    PtaStature: null,
    PtaStrength: null,
    PtaDairyForm: null,
    PtaRumpAngle: null,
    PtaThurlWidth: null,
    PtaRearLegs: null,
    PtaFootAngle: null,
    PtaDpr: null,
    PtaHcr: null,
    PtaCcr: null,
    PtaScs: null,
    PtaProdLife: null,
    PtaLiv: null,
    chkJh1Carrier: false,
    chkJh1Free: false,
    chkJh1Untested: false,
    chkJnsCarrier: false,
    chkJnsTestedFree: false,
    chkJnsPedigreeFree: false,
    chkJnsUntested: false,
    Jh1: null,
    Jns: null,
    PtaMfv: null,
    PtaDab: null,
    PtaKet: null,
    PtaMas: null,
    PtaMet: null,
    PtaRpl: null,
    Hti: null,
    chkRecordedStatus_SelectAll: false,
    chkPolledStatus_SelectAll: false,
    chkBetaCasein_SelectAll: false,
    chkAI_SelectAll: false,
    chkBreedEvaluationGroup_SelectAll: false,
    chkAIStatus_SelectAll: false,
    chkJH1_SelectAll: false,
    chkJNS_SelectAll: false,
}

// Control information used to auto generate controls for the filter
export const DefaultFilterControls = [
    {
        panelHeader: "Recorded Information",
        sections: [
            {
                sectionHeader: "Recorded Status",
                checkboxHeader: {
                    id: "SelectAllRecordedStatus",
                    type: "checkbox",
                    name: "chkRecordedStatus_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkHR",
                        label: "Herd Register (HR)",
                    },
                    {
                        type: "checkbox",
                        name: "chkGen6",
                        label: "Generation 6+",
                    },
                    {
                        type: "checkbox",
                        name: "chkGen5",
                        label: "Generation 5",
                    },
                    {
                        type: "checkbox",
                        name: "chkGen4",
                        label: "Generation 4",
                    },
                    {
                        type: "checkbox",
                        name: "chkGen3",
                        label: "Generation 3",
                    },
                    {
                        type: "checkbox",
                        name: "chkGen2",
                        label: "Generation 2",
                    },
                    {
                        type: "checkbox",
                        name: "chkJX",
                        label: "JX"
                    },
                    {
                        type: "checkbox",
                        name: "chkJXFree",
                        label: "JX-Free"
                    }
                ]
            },
            {
                sectionHeader: "Polled Status",
                checkboxHeader: {
                    id: "SelectAllPolledStatus",
                    type: "checkbox",
                    name: "chkPolledStatus_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkNotPolled",
                        label: "Not Polled",
                    },
                    {
                        type: "checkbox",
                        name: "chkPolled",
                        label: "Polled",
                    },
                    {
                        type: "checkbox",
                        name: "chkHomozygusPolled",
                        label: "Homozygus Polled"
                    }
                ]
            },
            {
                sectionHeader: "Beta Casein A2",
                checkboxHeader: {
                    id: "SelectAllBetaCasein",
                    type: "checkbox",
                    name: "chkBetaCasein_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkA2A2",
                        label: "A2/A2",
                    },
                    {
                        type: "checkbox",
                        name: "chkA1A1",
                        label: "A1/A1",
                    },
                    {
                        type: "checkbox",
                        name: "chkA1A2",
                        label: "A1/A2",
                    },
                    {
                        type: "checkbox",
                        name: "chkBcUntested",
                        label: "Not Tested",
                    }
                ]
            },
            {
                sectionHeader: "Inbreeding",
                options: [
                    {
                        type: "slider",
                        header: "GFI",
                        name: "Efi",
                        step: 0.1,
                    },
                ]
            },
            {
                sectionHeader: "Breed Base Representation",
                options: [
                    {
                        type: "slider",
                        header: "BBR",
                        name: "Bbr",
                        step: 1,
                    }
                ]
            },
            {
                sectionHeader: "Breed Evaluation Group",
                checkboxHeader: {
                    id: "SelectAllBreedEvaluationGroup",
                    type: "checkbox",
                    name: "chkBreedEvaluationGroup_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkSingleBreed",
                        label: "Single Breed Group (S)"
                    },
                    {
                        type: "checkbox",
                        name: "chkMultiBreed",
                        label: "Multi Breed Group (M)",
                    }
                ]
            },
            {
                sectionHeader: "AI Status",
                checkboxHeader: {
                    id: "SelectAllAIStatus",
                    type: "checkbox",
                    name: "chkAIStatus_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkAiActive",
                        label: "Active A.I. (A) Bulls",
                    },
                    {
                        type: "checkbox",
                        name: "chkAiForeign",
                        label: "Foreign (F) Bulls marketed in the U.S."
                    },
                    {
                        type: "checkbox",
                        name: "chkAiGenomic",
                        label: "Genomically Tested (G) Bulls without milking daughters",
                    }
                ]
            },
            {
                sectionHeader: "A.I. Organizations",
                checkboxHeader: {
                    id: "SelectAllAIOrganizations",
                    type: "checkbox",
                    name: "chkAI_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                ]
            }
        ]
    },
    {
        panelHeader: "Production",
        sections: [
            {
                sectionHeader: "",
                options: [
                    {
                        type: "slider",
                        header: "JPI - Jersey Performance Index",
                        name: "Jpi",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "CFP - Combined Fat Protein",
                        name: "Cfp",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "PTA Milk",
                        name: "PtaMilk",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "% Fat",
                        name: "PtaFatPercent",
                        step: .01,
                    },
                    {
                        type: "slider",
                        header: "PTA Fat",
                        name: "PtaFat",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "% Pro",
                        name: "PtaProteinPercent",
                        step: .01,
                    },
                    {
                        type: "slider",
                        header: "PTA Pro",
                        name: "PtaProtein",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "NM$ - Net Merit Dollars",
                        name: "NetMerit",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "CM$ - Cheese Merit Dollars",
                        name: "CheeseMerit",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "FM$ - Fluid Merit Dollars",
                        name: "FluidMerit",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "GM$ - Grazing Merit Dollars",
                        name: "GrazingMerit",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "REL % - Relative Percent",
                        name: "RelProtein",
                        step: 1,
                    }
                ]
            }
        ],
    },
    {
        panelHeader: "Udder",
        sections: [
            {
                sectionHeader: "",
                options: [
                    {
                        type: "slider",
                        header: "PTA JUI - Jersey Udder Index",
                        name: "Jui",
                        step: 1,
                    },
                    {
                        type: "slider",
                        header: "PTA FU - Fore Udder",
                        name: "PtaForeUdder",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RUH - Rear Udder Height",
                        name: "PtaRearUdderHeight",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RUW - Rear Udder Width",
                        name: "PtaRearUdderWidth",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA UC - Udder Cleft",
                        name: "PtaUdderCleft",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA UD - Udder Depth",
                        name: "PtaUdderDepth",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA TP - Teat Placement",
                        name: "PtaTeatPlacement",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA TL - Teat Length",
                        name: "PtaTeatLength",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RTR - Rear Teat Rear View",
                        name: "PtaRtr",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RTS - Rear Teat Side View",
                        name: "PtaRts",
                        step: 0.1,
                    },
                   
                ]
            }
        ],
    },
    {
        panelHeader: "Body",
        sections: [
            {
                sectionHeader: "",
                options: [
                    {
                        type: "slider",
                        header: "PTA FS - Final Score",
                        name: "PtaFinalScore",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA ST - Stature",
                        name: "PtaStature",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA SR - Strength",
                        name: "PtaStrength",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA DF - Dairy Form",
                        name: "PtaDairyForm",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RA - Rump Angle",
                        name: "PtaRumpAngle",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RW - Rump Width",
                        name: "PtaThurlWidth",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA RL - Rear Legs",
                        name: "PtaRearLegs",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA FA - Foot Angle",
                        name: "PtaFootAngle",
                        step: 0.1,
                    }
                ]
            }
        ],
    },
    {
        panelHeader: " Fitness and Fertility",
        sections: [
            {
                sectionHeader: "",
                options: [
                    {
                        type: "slider",
                        header: "PTA DPR - Daughter Pregnancy Rate",
                        name: "PtaDpr",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA HCR - Heifer Conception Rate",
                        name: "PtaHcr",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA CCR - Cow Conception Rate",
                        name: "PtaCcr",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA SCS - Somatic Cell Score",
                        name: "PtaScs",
                        step: 0.01,
                    },
                    {
                        type: "slider",
                        header: "PTA PL - Productive Life",
                        name: "PtaProdLife",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA LIV - Livability",
                        name: "PtaLiv",
                        step: 0.1,
                    }
                ]
            },
            {
                sectionHeader: "JH1 Haplotype",
                checkboxHeader: {
                    id: "SelectAllJH1Haplotype",
                    type: "checkbox",
                    name: "chkJH1_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkJh1Carrier",
                        label: "Tested Carrier (C)",
                    },
                    {
                        type: "checkbox",
                        name: "chkJh1Free",
                        label: "Tested Free (F)",
                    },
                    {
                        type: "checkbox",
                        name: "chkJh1Untested",
                        label: "Untested/No JH1 Record",
                    }
                ]
            },
            {
                sectionHeader: "JNS Haplotype",
                checkboxHeader: {
                    id: "SelectAllJNSHaplotype",
                    type: "checkbox",
                    name: "chkJNS_SelectAll",
                    label: "Select/Deselect All"
                },
                options: [
                    {
                        type: "checkbox",
                        name: "chkJnsCarrier",
                        label: "Tested Carrier (C)",
                    },
                    {
                        type: "checkbox",
                        name: "chkJnsTestedFree",
                        label: "Tested Free (TF)",
                    },
                    {
                        type: "checkbox",
                        name: "chkJnsPedigreeFree",
                        label: "Pedigree Free (PF)",
                    },
                    {
                        type: "checkbox",
                        name: "chkJnsUntested",
                        label: "Untested/No JNS Record",
                    }
                ]
            },
        ],
    },
    {
        panelHeader: "CDCB Health",
        sections: [
            {
                sectionHeader: "",
                options: [
                    {
                        type: "slider",
                        header: "Health Trait Index (HTI)",
                        name: "Hti",
                        step: 0.01,
                    },
                    {
                        type: "slider",
                        header: "PTA Milk Fever (MFV)",
                        name: "PtaMfv",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA Displaced Abomasum (DAB)",
                        name: "PtaDab",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA Ketosis (KET)",
                        name: "PtaKet",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA Mastitis (MAS)",
                        name: "PtaMas",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA Metritis (MET)",
                        name: "PtaMet",
                        step: 0.1,
                    },
                    {
                        type: "slider",
                        header: "PTA Retained Placenta (RPL)",
                        name: "PtaRpl",
                        step: 0.1,
                    },
                    
                ]
            }
        ],
    }
]

// Applied filter information used to display applied filters appropriately
export const AppliedBullFilterScheme = {
    chkHR: 'HR',
    chkGen2: 'Generation 2',
    chkGen3: 'Generation 3',
    chkGen4: 'Generation 4',
    chkGen5: 'Generation 5',
    chkGen6: 'Generation 6',
    chkJX: 'JX',
    chkJXFree: 'JX-Free',
    chkNotPolled: 'Not Polled',
    chkPolled: 'Polled',
    chkHomozygusPolled: 'Homozygus Polled',
    chkA2A2: 'A2/A2',
    chkA1A1: 'A1/A1',
    chkA1A2: 'A1/A2',
    chkBcUntested: 'Beta Casein Untested',
    Efi: 'GFI',
    Bbr: 'BBR',
    chkSingleBreed: 'Single Breed (S)',
    chkMultiBreed: 'Multi-Breed (M)',
    chkAiActive: 'AI Active',
    chkAiForeign: 'AI Foreign',
    chkAiGenomic: 'AI Genomic',
    chkAiSelectAll: 'All AI Organizations',
    Jpi: 'JPI',
    Cfp: 'CFP',
    PtaMilk: 'PTA Milk',
    PtaFatPercent: 'PTA Fat %',
    PtaFat: 'PTA Fat',
    PtaProteinPercent: 'PTA Protein %',
    PtaProtein: 'PTA Protein',
    NetMerit: 'NM$',
    CheeseMerit: 'CM$',
    FluidMerit: 'FM$',
    GrazingMerit: 'GM$',
    RelProtein: 'Rel Protein',
    PtaForeUdder: 'Pta FU',
    PtaRearUdderHeight: 'PTA RUH',
    PtaRearUdderWidth: 'PTA RUW',
    PtaUdderCleft: 'PTA UC',
    PtaUdderDepth: 'PTA UD',
    PtaTeatPlacement: 'PTA TP',
    PtaTeatLength: 'PTA TL',
    PtaRtr: 'PTA RTR',
    PtaRts: 'PTA RTS',
    Jui: 'JUI',
    PtaFinalScore: 'PTA FS',
    PtaStature: 'PTA ST',
    PtaStrength: 'PTA STR',
    PtaDairyForm: 'PTA DF',
    PtaRumpAngle: 'PTA RA',
    PtaThurlWidth: 'PTA RW',
    PtaRearLegs: 'PTA RL',
    PtaFootAngle: 'PTA FA',
    PtaDpr: 'PTA DPR',
    PtaHcr: 'PTA HCR',
    PtaCcr: 'PTA CCR',
    PtaScs: 'PTA SCS',
    PtaProdLife: 'PTA PL',
    PtaLiv: 'PTA LIV',
    chkJh1Carrier: 'JH1 Carrier',
    chkJh1Free: 'JH1 Free',
    chkJh1Untested: 'JH1 Untested',
    chkJnsCarrier: 'JNS Carrier',
    chkJnsTestedFree: 'JNS Tested Free',
    chkJnsPedigreeFree: 'JNS Pedigree Free',
    chkJnsUntested: 'JNS Untested',
    Jh1: 'JH1',
    Jns: 'JNS',
    PtaMfv: 'PTA MFV',
    PtaDab: 'PTA DAB',
    PtaKet: 'PTA KET',
    PtaMas: 'PTA MAS',
    PtaMet: 'PTA MET',
    PtaRpl: 'PTA RPL',
    Hti: 'HTI',
}
