import ContactForm from "./components/contact/ContactForm";
import MediaSlider from "./components/media-slider/MediaSlider";
import Navigation from "./components/navigation/Navigation";
import OrganizationBio from "./components/organization-bio/OrganizationBio";
import Footer from './components/footer/Footer'
import BullGrid from "./components/bull-grid/BullGrid";
import ScrollTop from "./components/scroll-top/ScrollTop";
import { BullGridProvider } from "./contexts/BullGridContext";

import Layout from '../src/components/layout/Layout';

import './App.css'
import './index.css'

function App() {
  return (
    <Layout startingTheme='light'>
      <Navigation />
      <MediaSlider />
      <BullGridProvider>
        <BullGrid />
      </BullGridProvider>
      <OrganizationBio />
      <ContactForm />
      <Footer />
      <ScrollTop />
    </Layout>
  );
}

export default App;
