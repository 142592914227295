
// Calculates the averages for each of the numerical valued columns in the filtered bull data
export function calculateAverages(filteredData) {
    // Only calculate averages if there is any data
    if (filteredData.length > 0) {
        
        // Create an object to add to the filtered bull data with the average
        var averages = {};
        // Iterate over the array of objects to get the keys
        var keys = Object.keys(filteredData[0]);

        // Iterate over the keys
        for (let i = 0; i < keys.length; ++i) {
            // Get the key and reset the item average for the new key
            let key = keys[i];
            var itemAverage = null;

            // Iterate over the bull data values for the corresponding key and calculate the average
            for (let j = 0; j < filteredData.length; j++) {
                // Get the bull data row key value
                const element = filteredData[j][key];

                // Check to see if the element is a number if so calculate average otherwise ignore
                if (isNaN(element) == false && element !== null && element !== '') {
                    itemAverage += element;
                    if (j == filteredData.length - 1) {
                        itemAverage = parseFloat(itemAverage / filteredData.length).toFixed(2);
                        averages[key] = itemAverage;
                    }
                }
            }
        }
        // Add an averages row description in the full name field
        averages.FullName = 'Active A.I, Foreign & Genomically Tested (G) Bulls Averages';
        return averages;
    }
}