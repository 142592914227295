// React imports
import React, { useContext } from 'react'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Packaged component imports
import { Container, Carousel, Button, Row, Col } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'

// Asset imports
import BullseyeLogo from '../../assets/images/BullseyeLogo.png'
import BullseyeImg2 from '../../assets/images/Bull_BG5.jpg'
import BullseyeImg1 from '../../assets/images/Pasture1.JPG'

// Css imports
import '../../stylesheets/MediaSlider.css'

function MediaSlider() {
    const { theme } = useContext(ThemeContext);

    function scrollToGrid() {
        var elementToView = document.getElementById('BullseyeGrid');
        if (elementToView !== null) {
            elementToView.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    function scrollToBio() {
        var elementToView = document.getElementById('Organization-Bio');
        if (elementToView !== null) {
            elementToView.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
    }

    return (
        <Container fluid className="MediaSlider-Container">
            <Container fluid className='MedialSlider-Banner-Container'>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <a href="#" target="_self" rel="noreferrer" className='text-decoration-none'>
                            <img className="MediaSlider-Banner-Logo" src={BullseyeLogo} alt="Bullseye Logo" />
                            <h2 className="MediaSlider-Banner-Name">Bullseye</h2>
                        </a>
                        <div className='MediaSlider-Banner-Seperator'></div>
                    </Col>
                </Row>
            </Container>
            <Carousel className="MediaSlider-Carousel">
                <Carousel.Item className='MediaSlider-Carousel-Item' style={{ backgroundImage: `url(${BullseyeImg1})` }}>
                    <Container fluid className="MediaSlider-Carousel-Item-Container">
                        <div className={`MediaSlider-Carousel-Item-Overlay ${theme}`}>
                            <div className='MediaSlider-Carousel-Caption'>
                                <h5 className='MediaSlider-Carousel-Caption-SubHeader'>Search, Sort, Select</h5>
                                <h1 className={`MediaSlider-Carousel-Caption-Header ${theme}`}>Bringing Bull Selection To Your Fingertips</h1>
                                <p className="MediaSlider-Carousel-Caption-SubText">
                                    Easily search, sort and select from the top Jersey bulls in the industry. Export or print your selection
                                    for even more sortability, do it all with just one click. Work with Active A.I. And Foreign Jersey Sires to choose
                                    the next bull to bring genetics and improvement to your herd.
                                </p>
                                <div className="MediaSlider-Carousel-Caption-Buttons">
                                    <Button className="MediaSlider-Carousel-Caption-Button" onClick={scrollToGrid}>
                                        Get Started&nbsp;
                                        <FaIcons.FaArrowRight className="MediaSlider-Caption-ButtonIcon" />
                                    </Button>
                                    <Button className="MediaSlider-Carousel-Caption-ButtonAlt" onClick={scrollToBio}>
                                        Learn More&nbsp;
                                        <FaIcons.FaPlus className="MediaSlider-Caption-ButtonIcon" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>
                <Carousel.Item className='MediaSlider-Carousel-Item' style={{ backgroundImage: `url(${BullseyeImg2})` }}>
                    <Container fluid className='MediaSlider-Carousel-Item-Container'>
                        <div className={`MediaSlider-Carousel-Item-Overlay ${theme}`}>
                            <div className='MediaSlider-Carousel-Caption'>
                                <h5 className='MediaSlider-Carousel-Caption-SubHeader'>Search, Sort, Select</h5>
                                <h1 className={`MediaSlider-Carousel-Caption-Header ${theme}`}>JerseyMate Integration</h1>
                                <p className="MediaSlider-Carousel-Caption-SubText">
                                    Bullseye allows you to sort A.I, Foreign Bulls marketed in the U.S and Genomically Tested Bulls without milking daughters all at the click of a button. You decide
                                    what criteria meets your standards to be used within your herd. Where we stand apart is taking it one step further allowing you to not only filter but to choose a set
                                    of bulls to import into a JerseyMate mating group seamlessly or you can choose to export the bulls to excel for a deeper analysis.
                                </p>
                                <div className="MediaSlider-Carousel-Caption-Buttons">
                                    <Button className="MediaSlider-Carousel-Caption-Button" onClick={scrollToGrid}>
                                        Get Started&nbsp;
                                        <FaIcons.FaArrowRight className="MediaSlider-Caption-ButtonIcon" />
                                    </Button>
                                    <Button className="MediaSlider-Carousel-Caption-ButtonAlt" onClick={scrollToBio}>
                                        Learn More&nbsp;
                                        <FaIcons.FaPlus className="MediaSlider-Caption-ButtonIcon" />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Carousel.Item>
            </Carousel>
        </Container>
    )
}

export default MediaSlider
