// React imports
import React, { useRef, useContext, useState } from 'react'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Package component imports
import { Alert } from '@mui/material'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import emailjs from 'emailjs-com'
import * as FaIcons from 'react-icons/fa'

// Css imports
import '../../stylesheets/ContactForm.css'

function ContactForm() {
    const [emailSent, setEmailSent] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { theme } = useContext(ThemeContext);

    {/* Get the reference to the contact us form set below*/ }
    const form = useRef();

    {/* Function to send the contact email using the emailJS service, use the EmailJS API documentation */ }
    function sendEmail(event) {
        event.preventDefault();
        emailjs.sendForm('service_kd1f2ne', 'template_g4anhpn', form.current, 'user_ouhCQ01VN6e4n8aO3rmYf')
            .then((result) => {
                setEmailSent(true);
                setHasError(false);
                document.getElementById('Contact-Us-Form').reset();
            }, (error) => { 
                setEmailSent(false);
                setHasError(true);
            })
    }

    return (
        <Container fluid className={`ContactForm-Container ${theme}`}>
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h5 className={`ContactForm-SubHeader ${theme}`}>Have some questions?</h5>
                    <h1 className={`ContactForm-Header ${theme}`}>Contact Our Team!</h1>
                    <p className={`ContactForm-SubText ${theme}`}>
                        To let us know about your experience or to answer any questions you may have, please fill out the form below and we will get back to you promptly.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <div className={`ContactForm-EmailIcon-OuterWrapper ${theme}`}>
                        <div className={`ContactForm-EmailIcon-InnerWrapper ${theme}`}>
                            <FaIcons.FaRegEnvelopeOpen className='ContactForm-EmailIcon' />
                        </div>
                    </div>
                </Col>
                <Col xs="12" sm="12" md="6" lg="6" xl="6">
                    <Form id="Contact-Us-Form" ref={form} onSubmit={sendEmail} className='ContactForm-Form'>
                        <Form.Group className='ContactForm-Form-Group'>
                            <Form.Label className={`ContactForm-Form-Label ${theme}`}>First Name</Form.Label>
                            <Form.Control type="text" className='ContactForm-Form-Control' name='first_name' required></Form.Control>
                        </Form.Group>
                        <Form.Group className='ContactForm-Form-Group'>
                            <Form.Label className={`ContactForm-Form-Label ${theme}`}>Last Name</Form.Label>
                            <Form.Control type="text" className='ContactForm-Form-Control' name="last_name" required></Form.Control>
                        </Form.Group>
                        <Form.Group className='ContactForm-Form-Group'>
                            <Form.Label className={`ContactForm-Form-Label ${theme}`}>Email Address</Form.Label>
                            <Form.Control type="email" className='ContactForm-Form-Control' name="from_email_address" required></Form.Control>
                            <Form.Text className='ContactForm-Form-Text'>
                                We'll never share your email with anyone else
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="ContactForm-Form-Group">
                            <Form.Control className='ContactForm-Form-Control' as="textarea" placeholder="Message..." rows={5} name="message" required></Form.Control>
                        </Form.Group>
                        <Button className='ContactForm-Form-Send mb-3' type="submit" value="Send">
                            Send
                        </Button>
                        {
                            emailSent &&
                            <Alert variant='filled' onClose={() => { setEmailSent(false) }} severity='success'>Contact email successfully sent!</Alert>
                        }
                        {
                            hasError &&
                            <Alert variant='filled' onClose={() => { setHasError(false) }} severity='error'>Contact email failed to send!</Alert>
                        }
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}

export default ContactForm
