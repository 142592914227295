// React imports
import React, { createContext    } from "react";

// Hook imports
import UseTheme from '../hooks/UseTheme';

// Creates a context to be used for themes
export const ThemeContext = createContext();

function ThemeProvider({ children, startingTheme }) {
    const {
        theme,
        setTheme
    } = UseTheme(startingTheme);

    return (
        <ThemeContext.Provider value={{ theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    )
}

export { ThemeProvider };