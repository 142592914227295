// React imports
import React from 'react'

// Packaged component imports
import { Backdrop } from '@mui/material'

// Asset imports
import DarkLoader from '../../assets/images/Preloader_Orange.gif'

// CSS imports
import '../../stylesheets/Loader.css'

function Loader({show}) {
  return (
    <>
        <Backdrop className='Loader-Backdrop' open={show}>
            <img src={DarkLoader} alt="LoadingImg" />
        </Backdrop>
    </>
  )
}

export default Loader