import { AppliedBullFilterScheme } from "../models/BullFilter";

// Parses out the filters that have been applied based on changes between the updated bull filter and the default
export function getAppliedFilters(updatedBullFilter, defaultBullFilter) {
    // Create an array to hold the applied filters objects
    var appliedFilters = [];

    // Get all the keys that are in the bull filter
    var keys = Object.keys(updatedBullFilter);
    keys = keys.filter(k => !k.includes('SelectAll'));

    // Iterate over the keys
    for (let i = 0; i < keys.length; ++i) {
        // Get the key value
        let key = keys[i];

        // Check to see if the new filter key value is the same as the default if so no need to add just skip
        if (Array.isArray(updatedBullFilter[key])) {
            if (updatedBullFilter[key] !== null && defaultBullFilter[key] !== null)
            {
                if (updatedBullFilter[key][0] !== defaultBullFilter[key][0] || updatedBullFilter[key][1] !== defaultBullFilter[key][1]) {
                    // Create a applied filter object and push to array
                    let appliedFilter = {
                        'id': key,
                        'name': getAppliedFilterName(key),
                        'value': getAppliedFilterValue(updatedBullFilter[key])
                    }
                    appliedFilters.push(appliedFilter);
                }
            }
        }
        else if (updatedBullFilter[key] !== defaultBullFilter[key]) {
            // Create a applied filter object and push to array
            let appliedFilter = {
                'id': key,
                'name': getAppliedFilterName(key),
                'value': getAppliedFilterValue(updatedBullFilter[key], defaultBullFilter[key])
            }
            appliedFilters.push(appliedFilter);
        }
    }
    // Return the applied filters
    return appliedFilters;
}

// Parses the applied filter key into it's proper display name
function getAppliedFilterName(valueName) {
    var parsedName = '';

    if (valueName.includes('chkAI_')) {
        // AI Stud Parse    
        parsedName = valueName.replace('chkAI_', '').replace('_', ' ');
    }
    else if (AppliedBullFilterScheme[valueName] !== null) {
        // Display Name Parse
        parsedName = AppliedBullFilterScheme[valueName];
    }
    return parsedName;
}

// Parses the applied filter value into it's proper display
function getAppliedFilterValue(value, defaultValue) {
    var parsedValue = '';
    if (Array.isArray(value)) {
        parsedValue = `: ${value[0]} to ${value[1]}`;
    }
    return parsedValue;
}