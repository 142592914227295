// React imports
import React, { useContext, useEffect } from 'react'

// Context Imports
import { JerseyMateFormContext } from '../../contexts/JerseyMateFormContext'
import { ThemeContext } from '../../contexts/ThemeContext'

// Package Component Imports
import { Dialog, DialogTitle, TextField, Button, DialogContent, DialogContentText, DialogActions } from '@mui/material'

// Asset Imports
import JerseyMateLogo from '../../assets/images/JM_LogoSeperate.png'

// CSS Imports
import '../../stylesheets/JerseyMateForm.css'

function JerseyMateForm({ regIDs }) {
    const { showEntryForm, closeEntryForm, errors, matingGroup, handleChange, handleSubmit } = useContext(JerseyMateFormContext);
    const { theme } = useContext(ThemeContext);

    // Use Effect to pre-populate the registration IDs to the form
    useEffect(() => {
        matingGroup.regIDS = regIDs;
    }, [regIDs, matingGroup])

    return (
        <Dialog className={`JerseyMateForm ${theme}`} open={showEntryForm} onClose={closeEntryForm}>
            <DialogTitle className={`JerseyMateForm-DialogTitle ${theme}`}>Create JerseyMate Mating Group</DialogTitle>
            <DialogContent className={`JerseyMateForm-DialogContent ${theme}`}>
                <DialogContentText className={`JerseyMateForm-DialogContentText ${theme}`}>
                    Run JerseyMate with this group of bulls by entering your herd number and mating group name below. Then go to JerseyMate to add females and submit your mating
                    for processing.
                </DialogContentText>
                <div className="JerseyMateForm-LogoContainer">
                    <img alt="JerseyMateLogo" className="JerseyMateForm-Logo" src={JerseyMateLogo} />
                </div>
                <TextField
                    className={`JerseyMateForm-TextField ${theme}`}
                    focused
                    error={errors.herdID ?? false}
                    helperText={errors.herdID}
                    id="outlined-error"
                    name="herdID"
                    label="Herd Number"
                    type="text"
                    fullWidth
                    variant='outlined'
                    margin='dense'
                    value={matingGroup.herdID}
                    onChange={handleChange} />
                <TextField
                    className={`JerseyMateForm-TextField ${theme}`}
                    focused
                    error={errors.matingGroupName ?? false}
                    helperText={errors.matingGroupName}
                    id="matingGroupName"
                    name="matingGroupName"
                    label="Mating Group Name"
                    type="text"
                    fullWidth
                    variant='outlined'
                    margin="dense"
                    value={matingGroup.matingGroupName}
                    onChange={handleChange} />
                <TextField 
                    className={`JerseyMateForm-TextField ${theme}`}
                    focused maxRows={5}
                    multiline
                    error={errors.regIDs ?? false}
                    helperText={errors.regIDs}
                    id='regIDs'
                    name='regIDs'
                    label="Selected Bulls"
                    type="text"
                    fullWidth
                    variant='outlined'
                    margin='dense' 
                    InputProps={{
                        readOnly: true,
                      }}
                    value={matingGroup.regIDs != null ? matingGroup.regIDs.join("\r\n") : matingGroup.regIDS} />
            </DialogContent>
            <DialogActions className={`JerseyMateForm-DialogActions ${theme}`}>
                <Button onClick={closeEntryForm}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default JerseyMateForm;