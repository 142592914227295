// React imports
import React, { useContext } from 'react';

// Context imports
import { BullGridContext } from '../../contexts/BullGridContext';
import { ThemeContext } from '../../contexts/ThemeContext'
import { DefaultBullColumnControls } from '../../models/BullColumns';

// Package component imports
import { Tooltip } from '@mui/material';
import { Container, Button, Form } from 'react-bootstrap'
import * as MdIcons from 'react-icons/md'

// Css imports
import '../../stylesheets/BullColumns.css'

function BullColumns() {
  const { resetColumns, gridDisplayColumns, handleDisplayColumnCheckboxChange } = useContext(BullGridContext);
  const { theme } = useContext(ThemeContext);

  return (
    <Container fluid className='BullColumns-Container'>
      <Tooltip title="Reset" arrow>
        <Button className={`BullColumns-Reset-Button ${theme}`} variant='secondary' onClick={resetColumns}>
          <MdIcons.MdRestartAlt />
        </Button>
      </Tooltip>
      {
        DefaultBullColumnControls.map((section, index) => {
          return (
            <div className='ColumnSection-Container' key={index}>
              <h5 className={`ColumnSection-Header ${theme}`}>{section.sectionHeader}</h5>
              {
                section.options.map((option, key) => {
                  return <Form.Check
                    className="ColumnSection-Checkbox"
                    onChange={handleDisplayColumnCheckboxChange}
                    checked={gridDisplayColumns[option.name]}
                    type="switch"
                    name={option.name}
                    label={option.label}
                    key={key} />
                })
              }
            </div>
          )
        })
      }
    </Container>
  );
}

export default BullColumns;
