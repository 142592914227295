// React imports
import React, { useContext } from 'react'

// Context imports
import { ThemeContext } from '../../contexts/ThemeContext'

// Packaged component imports
import { Button } from 'react-bootstrap'
import * as FaIcons from 'react-icons/fa'

// Css imports
import '../../stylesheets/ScrollTop.css'

function ScrollTop() {
    const { theme } = useContext(ThemeContext);

    // Function that takes the user to the top of the current document
    function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div className={`ScrollTop-Button ${theme}`}>
            <Button onClick={topFunction} variant="secondary">
                <FaIcons.FaArrowUp />
            </Button>
        </div>
    )
}

export default ScrollTop
